import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { fetchWrapper, history } from '../_helpers';

const profilSubject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_APIURL}/api/Profil`;

export const profilService = {
    getAllProfil,
    getProfilById,
    createProfil,
    updateProfil,
    deleteProfil: _delete,
    profil: profilSubject.asObservable(),
    get profilValue () { return profilSubject.value }
};

function getAllProfil() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}

function getProfilById(id) {
    return fetchWrapper.get(`${baseUrl}/getById/${id}`);
}

function createProfil(params) {
    
    return fetchWrapper.post(`${baseUrl}/add`, params);
}

function updateProfil(id, params) {
    
    return fetchWrapper.put(`${baseUrl}/update/${id}`, params)
        .then(profil => {
            return profil;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/delete/${id}`)
        .then(x => {
            return x;
        });
}