import React, { useState, useEffect } from 'react'
import { alertService } from '../../_services';


const baseUrl = `${process.env.REACT_APP_APIURL}/api/Candidate`;



export default function FileUpload(props) {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [fileType, setFileType] = useState();

    const saveFile = (e) => {
        
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        //uploadFile(e);
    };

    const getDocumentType = (e) => {

        setFileType(e.target.value );
    }

    const uploadFile = async (e) => {
        
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("DocumentName", fileName );
        formData.append("DocumentType", fileType);
        formData.append("candidateId", props.idCandidate);
        
            
        await fetch(

            `${baseUrl}/file/upload`,

            {
                method: 'POST',
                body: formData,
            }

        )
        .then( response => { 
            
            alertService.success('Fichier ajoutée avec succès', { keepAfterRouteChange: true });

        })
        .catch((error) => {
            console.error('Error:', error);
        });
        
    };


    
    // const onFileUpload = async(e) => {
    //     e.preventDefault()
    //     const reader = new FileReader()
    //     reader.onload = async(e) => {
    //     const filedata = (e.target.result);
    //     const blob = new Blob([filedata]);
    //     const url = URL.createObjectURL(blob);
    //     const img = document.getElementById('img');
    //     img.src = url;
    //     // So the Blob can be Garbage Collected
    //     img.onload = e => URL.revokeObjectURL(url);
    //     };
    //     reader.readAsArrayBuffer(e.target.files[0])
    // }


  return (
      <>
        <div className='mt-3'>
            <h4>Ajouter un ficher</h4>
            <input type="file" onChange={saveFile} /> <br />
            
            <div className =" mt-3 col-4">
                <select className="form-select" aria-label="Type de document" onChange={getDocumentType}>
                    <option value=""></option>
                    <option value="0">CV</option>
                    <option value="1">Compte Rendu</option>
                    <option value="2">Autre</option>
                </select>
            </div>
           
            <input type="button" value="Enregistrer image" className='btn btn-primary mt-3' onClick={uploadFile} /> <br />
            
        </div>

      </>
    
  )
}
