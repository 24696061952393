import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import { history } from './_helpers';
import { accountService } from './_services';
import { App } from './app/Index';

import './public/css/global.css';

// attempt silent token refresh before startup
//accountService.refreshToken().then(accountService.logout()).finally(startApp);
//accountService.logout().finally(startApp);
accountService.refreshToken().finally(startApp);
//startApp();

function startApp() { 
    const root = ReactDOM.createRoot(document.getElementById('app'));
    root.render(
        <Router history={history}>
            <App />
        </Router>
    );
}





