
const nationalityCountry =
[
    {
        "id": 1,
        "nationalite": "Afghane",
        "libelle": "Afghanistan"
    },
    {
        "id": 2,
        "nationalite": "Sudafricaine",
        "libelle": "Afrique du Sud"
    },
    {
        "id": 3,
        "nationalite": "Albanaise",
        "libelle": "Albanie"
    },
    {
        "id": 4,
        "nationalite": "Algérienne",
        "libelle": "Algérie"
    },
    {
        "id": 5,
        "nationalite": "Allemande",
        "libelle": "Allemagne"
    },
    {
        "id": 6,
        "nationalite": "Andorrane",
        "libelle": "Andorre"
    },
    {
        "id": 7,
        "nationalite": "Angolaise",
        "libelle": "Angola"
    },
    {
        "id": 8,
        "nationalite": "Antiguayenne",
        "libelle": "Antigua-et-Barbuda"
    },
    {
        "id": 9,
        "nationalite": "Saoudienne",
        "libelle": "Arabie saoudite"
    },
    {
        "id": 10,
        "nationalite": "Argentine",
        "libelle": "Argentine"
    },
    {
        "id": 11,
        "nationalite": "Arménienne",
        "libelle": "Arménie"
    },
    {
        "id": 12,
        "nationalite": "Australienne",
        "libelle": "Australie"
    },
    {
        "id": 13,
        "nationalite": "Autrichienne",
        "libelle": "Autriche"
    },
    {
        "id": 14,
        "nationalite": "Azerbaïdjanaise",
        "libelle": "Azerbaïdjan"
    },
    {
        "id": 15,
        "nationalite": "Bahaméenne",
        "libelle": "Bahamas"
    },
    {
        "id": 16,
        "nationalite": "Bahreïnienne",
        "libelle": "Bahreïn"
    },
    {
        "id": 17,
        "nationalite": "Bangladaise",
        "libelle": "Bangladesh"
    },
    {
        "id": 18,
        "nationalite": "Barbadienne",
        "libelle": "Barbade"
    },
    {
        "id": 19,
        "nationalite": "Belge",
        "libelle": "Belgique"
    },
    {
        "id": 20,
        "nationalite": "Bélizienne",
        "libelle": "Belize"
    },
    {
        "id": 22,
        "nationalite": "Bhoutanaise",
        "libelle": "Bhoutan"
    },
    {
        "id": 24,
        "nationalite": "Birmane",
        "libelle": "Birmanie"
    },
    {
        "id": 23,
        "nationalite": "Biélorusse",
        "libelle": "Biélorussie"
    },
    {
        "id": 25,
        "nationalite": "Bolivienne",
        "libelle": "Bolivie"
    },
    {
        "id": 26,
        "nationalite": "Bosnienne",
        "libelle": "Bosnie-Herzégovine"
    },
    {
        "id": 27,
        "nationalite": "Botswanaise",
        "libelle": "Botswana"
    },
    {
        "id": 29,
        "nationalite": "Brunéienne",
        "libelle": "Brunei"
    },
    {
        "id": 28,
        "nationalite": "Brésilienne",
        "libelle": "Brésil"
    },
    {
        "id": 30,
        "nationalite": "Bulgare",
        "libelle": "Bulgarie"
    },
    {
        "id": 31,
        "nationalite": "Burkinabé",
        "libelle": "Burkina"
    },
    {
        "id": 32,
        "nationalite": "Burundaise",
        "libelle": "Burundi"
    },
    {
        "id": 21,
        "nationalite": "Béninoise",
        "libelle": "Bénin"
    },
    {
        "id": 33,
        "nationalite": "Cambodgienne",
        "libelle": "Cambodge"
    },
    {
        "id": 34,
        "nationalite": "Camerounaise",
        "libelle": "Cameroun"
    },
    {
        "id": 35,
        "nationalite": "Canadienne",
        "libelle": "Canada"
    },
    {
        "id": 36,
        "nationalite": "Cap-Verdienne",
        "libelle": "Cap-Vert"
    },
    {
        "id": 144,
        "nationalite": "Centrafricaine",
        "libelle": "Centrafrique"
    },
    {
        "id": 37,
        "nationalite": "Chilienne",
        "libelle": "Chili"
    },
    {
        "id": 38,
        "nationalite": "Chinoise",
        "libelle": "Chine"
    },
    {
        "id": 39,
        "nationalite": "Chypriote",
        "libelle": "Chypre"
    },
    {
        "id": 40,
        "nationalite": "Colombienne",
        "libelle": "Colombie"
    },
    {
        "id": 41,
        "nationalite": "Comorienne",
        "libelle": "Comores"
    },
    {
        "id": 42,
        "nationalite": "Congolaise",
        "libelle": "Congo"
    },
    {
        "id": 43,
        "nationalite": "Nord-coréenne",
        "libelle": "Corée du Nord"
    },
    {
        "id": 44,
        "nationalite": "Sud-coréenne",
        "libelle": "Corée du Sud"
    },
    {
        "id": 45,
        "nationalite": "Costaricienne",
        "libelle": "Costa Rica"
    },
    {
        "id": 47,
        "nationalite": "Croate",
        "libelle": "Croatie"
    },
    {
        "id": 48,
        "nationalite": "Cubaine",
        "libelle": "Cuba"
    },
    {
        "id": 46,
        "nationalite": "Ivoirienne",
        "libelle": "Côte d'Ivoire"
    },
    {
        "id": 49,
        "nationalite": "Danoise",
        "libelle": "Danemark"
    },
    {
        "id": 50,
        "nationalite": "Djiboutienne",
        "libelle": "Djibouti"
    },
    {
        "id": 51,
        "nationalite": "Dominiquaise",
        "libelle": "Dominique"
    },
    {
        "id": 52,
        "nationalite": "Egyptienne",
        "libelle": "Egypte"
    },
    {
        "id": 53,
        "nationalite": "Emirienne",
        "libelle": "Emirats arabes unis"
    },
    {
        "id": 54,
        "nationalite": "Equatorienne",
        "libelle": "Equateur"
    },
    {
        "id": 55,
        "nationalite": "Erythréenne",
        "libelle": "Erythrée"
    },
    {
        "id": 56,
        "nationalite": "Espagnole",
        "libelle": "Espagne"
    },
    {
        "id": 57,
        "nationalite": "Estonienne",
        "libelle": "Estonie"
    },
    {
        "id": 58,
        "nationalite": "Américaine",
        "libelle": "Etats-Unis"
    },
    {
        "id": 59,
        "nationalite": "Ethiopienne",
        "libelle": "Ethiopie"
    },
    {
        "id": 60,
        "nationalite": "Fidjienne",
        "libelle": "Fidji"
    },
    {
        "id": 61,
        "nationalite": "Finlandaise",
        "libelle": "Finlande"
    },
    {
        "id": 62,
        "nationalite": "Française",
        "libelle": "France"
    },
    {
        "id": 63,
        "nationalite": "Gabonaise",
        "libelle": "Gabon"
    },
    {
        "id": 64,
        "nationalite": "Gambienne",
        "libelle": "Gambie"
    },
    {
        "id": 66,
        "nationalite": "Ghanéenne",
        "libelle": "Ghana"
    },
    {
        "id": 68,
        "nationalite": "Grenadienne",
        "libelle": "Grenade"
    },
    {
        "id": 67,
        "nationalite": "Grecque",
        "libelle": "Grèce"
    },
    {
        "id": 69,
        "nationalite": "Guatémaltèque",
        "libelle": "Guatemala"
    },
    {
        "id": 70,
        "nationalite": "Guinéenne",
        "libelle": "Guinée"
    },
    {
        "id": 71,
        "nationalite": "Equatoguinéenne",
        "libelle": "Guinée équatoriale"
    },
    {
        "id": 72,
        "nationalite": "Bissaoguinéenne",
        "libelle": "Guinée-Bissao"
    },
    {
        "id": 73,
        "nationalite": "Guyanienne",
        "libelle": "Guyana"
    },
    {
        "id": 65,
        "nationalite": "Géorgienne",
        "libelle": "Géorgie"
    },
    {
        "id": 74,
        "nationalite": "Haïtienne",
        "libelle": "Haïti"
    },
    {
        "id": 75,
        "nationalite": "Hondurienne",
        "libelle": "Honduras"
    },
    {
        "id": 77,
        "nationalite": "Hongroise",
        "libelle": "Hongrie"
    },
    {
        "id": 79,
        "nationalite": "Indienne",
        "libelle": "Inde"
    },
    {
        "id": 80,
        "nationalite": "Indonésienne",
        "libelle": "Indonésie"
    },
    {
        "id": 81,
        "nationalite": "Irakienne",
        "libelle": "Irak"
    },
    {
        "id": 82,
        "nationalite": "Iranienne",
        "libelle": "Iran"
    },
    {
        "id": 83,
        "nationalite": "Irlandaise",
        "libelle": "Irlande"
    },
    {
        "id": 84,
        "nationalite": "Islandaise",
        "libelle": "Islande"
    },
    {
        "id": 85,
        "nationalite": "Israélienne",
        "libelle": "Israël"
    },
    {
        "id": 86,
        "nationalite": "Italienne",
        "libelle": "Italie"
    },
    {
        "id": 87,
        "nationalite": "Jamaïquaine",
        "libelle": "Jamaïque"
    },
    {
        "id": 88,
        "nationalite": "Japonaise",
        "libelle": "Japon"
    },
    {
        "id": 90,
        "nationalite": "Jordanienne",
        "libelle": "Jordanie"
    },
    {
        "id": 89,
        "nationalite": "Palestinienne",
        "libelle": "Jérusalem - Territoires palestiniens"
    },
    {
        "id": 91,
        "nationalite": "Kazakhstanais",
        "libelle": "Kazakhstan"
    },
    {
        "id": 92,
        "nationalite": "Kényane",
        "libelle": "Kenya"
    },
    {
        "id": 93,
        "nationalite": "Kirghize",
        "libelle": "Kirghizstan"
    },
    {
        "id": 94,
        "nationalite": "Kiribatienne",
        "libelle": "Kiribati"
    },
    {
        "id": 198,
        "nationalite": "Kosovare",
        "libelle": "Kosovo"
    },
    {
        "id": 95,
        "nationalite": "Koweïtienne",
        "libelle": "Koweït"
    },
    {
        "id": 96,
        "nationalite": "Laotienne",
        "libelle": "Laos"
    },
    {
        "id": 97,
        "nationalite": "Lesothienne",
        "libelle": "Lesotho"
    },
    {
        "id": 98,
        "nationalite": "Lettone",
        "libelle": "Lettonie"
    },
    {
        "id": 99,
        "nationalite": "Libanaise",
        "libelle": "Liban"
    },
    {
        "id": 100,
        "nationalite": "Libérienne",
        "libelle": "Liberia"
    },
    {
        "id": 101,
        "nationalite": "Libyenne",
        "libelle": "Libye"
    },
    {
        "id": 102,
        "nationalite": "Liechtensteinoise",
        "libelle": "Liechtenstein"
    },
    {
        "id": 103,
        "nationalite": "Lituanienne",
        "libelle": "Lituanie"
    },
    {
        "id": 104,
        "nationalite": "Luxembourgeoise",
        "libelle": "Luxembourg"
    },
    {
        "id": 105,
        "nationalite": "Macédonienne",
        "libelle": "Macédoine"
    },
    {
        "id": 106,
        "nationalite": "Malgache",
        "libelle": "Madagascar"
    },
    {
        "id": 107,
        "nationalite": "Malaisienne",
        "libelle": "Malaisie"
    },
    {
        "id": 108,
        "nationalite": "Malawienne",
        "libelle": "Malawi"
    },
    {
        "id": 109,
        "nationalite": "Maldivienne",
        "libelle": "Maldives"
    },
    {
        "id": 110,
        "nationalite": "Malienne",
        "libelle": "Mali"
    },
    {
        "id": 111,
        "nationalite": "Maltaise",
        "libelle": "Malte"
    },
    {
        "id": 112,
        "nationalite": "Marocaine",
        "libelle": "Maroc"
    },
    {
        "id": 113,
        "nationalite": "Marshallaise",
        "libelle": "Marshall"
    },
    {
        "id": 114,
        "nationalite": "Mauricienne",
        "libelle": "Maurice"
    },
    {
        "id": 115,
        "nationalite": "Mauritanienne",
        "libelle": "Mauritanie"
    },
    {
        "id": 116,
        "nationalite": "Mexicaine",
        "libelle": "Mexique"
    },
    {
        "id": 117,
        "nationalite": "Micronésienne",
        "libelle": "Micronésie"
    },
    {
        "id": 118,
        "nationalite": "Moldave",
        "libelle": "Moldavie"
    },
    {
        "id": 119,
        "nationalite": "Monégasque",
        "libelle": "Monaco"
    },
    {
        "id": 120,
        "nationalite": "Mongole",
        "libelle": "Mongolie"
    },
    {
        "id": 197,
        "nationalite": "Monténégrine",
        "libelle": "Monténégro"
    },
    {
        "id": 121,
        "nationalite": "Mozambicaine",
        "libelle": "Mozambique"
    },
    {
        "id": 122,
        "nationalite": "Namibienne",
        "libelle": "Namibie"
    },
    {
        "id": 123,
        "nationalite": "Nauruane",
        "libelle": "Nauru"
    },
    {
        "id": 125,
        "nationalite": "Nicaraguayenne",
        "libelle": "Nicaragua"
    },
    {
        "id": 126,
        "nationalite": "Nigérienne",
        "libelle": "Niger"
    },
    {
        "id": 127,
        "nationalite": "Nigériane",
        "libelle": "Nigeria"
    },
    {
        "id": 128,
        "nationalite": "Norvégienne",
        "libelle": "Norvège"
    },
    {
        "id": 129,
        "nationalite": "Néo-Zélandaise",
        "libelle": "Nouvelle-Zélande"
    },
    {
        "id": 124,
        "nationalite": "Népalaise",
        "libelle": "Népal"
    },
    {
        "id": 130,
        "nationalite": "Omanaise",
        "libelle": "Oman"
    },
    {
        "id": 131,
        "nationalite": "Ougandaise",
        "libelle": "Ouganda"
    },
    {
        "id": 132,
        "nationalite": "Ouzbèke",
        "libelle": "Ouzbékistan"
    },
    {
        "id": 133,
        "nationalite": "Pakistanaise",
        "libelle": "Pakistan"
    },
    {
        "id": 134,
        "nationalite": "Palaoise",
        "libelle": "Palaos"
    },
    {
        "id": 135,
        "nationalite": "Panaméenne",
        "libelle": "Panama"
    },
    {
        "id": 136,
        "nationalite": "Papouasienne",
        "libelle": "Papouasie-Nouvelle-Guinée"
    },
    {
        "id": 137,
        "nationalite": "Paraguayenne",
        "libelle": "Paraguay"
    },
    {
        "id": 138,
        "nationalite": "Néerlandaise",
        "libelle": "Pays-Bas"
    },
    {
        "id": 140,
        "nationalite": "Philippine",
        "libelle": "Philippines"
    },
    {
        "id": 141,
        "nationalite": "Polonaise",
        "libelle": "Pologne"
    },
    {
        "id": 142,
        "nationalite": "Portugaise",
        "libelle": "Portugal"
    },
    {
        "id": 139,
        "nationalite": "Péruvienne",
        "libelle": "Pérou"
    },
    {
        "id": 143,
        "nationalite": "Qatarienne",
        "libelle": "Qatar"
    },
    {
        "id": 148,
        "nationalite": "Roumaine",
        "libelle": "Roumanie"
    },
    {
        "id": 149,
        "nationalite": "Britannique",
        "libelle": "Royaume-Uni"
    },
    {
        "id": 150,
        "nationalite": "Russe",
        "libelle": "Russie"
    },
    {
        "id": 151,
        "nationalite": "Rwandaise",
        "libelle": "Rwanda"
    },
    {
        "id": 146,
        "nationalite": "Dominicaine",
        "libelle": "République dominicaine"
    },
    {
        "id": 145,
        "nationalite": "Congolaise (RDC)",
        "libelle": "République démocratique du Congo"
    },
    {
        "id": 147,
        "nationalite": "Tchèque",
        "libelle": "République tchèque"
    },
    {
        "id": 152,
        "nationalite": "Christophienne",
        "libelle": "Saint-Christophe-et-Niévès"
    },
    {
        "id": 154,
        "nationalite": "Marinaise",
        "libelle": "Saint-Marin"
    },
    {
        "id": 155,
        "nationalite": "Vaticanais",
        "libelle": "Saint-Siège"
    },
    {
        "id": 156,
        "nationalite": "Vincentaise",
        "libelle": "Saint-Vincent-et-les Grenadines"
    },
    {
        "id": 153,
        "nationalite": "Lucienne",
        "libelle": "Sainte-Lucie"
    },
    {
        "id": 157,
        "nationalite": "Salomonaise",
        "libelle": "Salomon"
    },
    {
        "id": 158,
        "nationalite": "Salvadorienne",
        "libelle": "Salvador"
    },
    {
        "id": 159,
        "nationalite": "Samoene",
        "libelle": "Samoa"
    },
    {
        "id": 160,
        "nationalite": "Santoméenne",
        "libelle": "Sao Tomé-et-Principe"
    },
    {
        "id": 162,
        "nationalite": "Serbe",
        "libelle": "Serbie"
    },
    {
        "id": 163,
        "nationalite": "Seychelloise",
        "libelle": "Seychelles"
    },
    {
        "id": 164,
        "nationalite": "Sierraléonaise",
        "libelle": "Sierra Leone"
    },
    {
        "id": 165,
        "nationalite": "Singapourienne",
        "libelle": "Singapour"
    },
    {
        "id": 166,
        "nationalite": "Slovaque",
        "libelle": "Slovaquie"
    },
    {
        "id": 167,
        "nationalite": "Slovène",
        "libelle": "Slovénie"
    },
    {
        "id": 168,
        "nationalite": "Somalienne",
        "libelle": "Somalie"
    },
    {
        "id": 169,
        "nationalite": "Soudanaise",
        "libelle": "Soudan"
    },
    {
        "id": 170,
        "nationalite": "Srilankaise",
        "libelle": "Sri Lanka"
    },
    {
        "id": 172,
        "nationalite": "Suisse",
        "libelle": "Suisse"
    },
    {
        "id": 173,
        "nationalite": "Surinamaise",
        "libelle": "Suriname"
    },
    {
        "id": 171,
        "nationalite": "Suédoise",
        "libelle": "Suède"
    },
    {
        "id": 174,
        "nationalite": "Swazie",
        "libelle": "Swaziland"
    },
    {
        "id": 175,
        "nationalite": "Syrienne",
        "libelle": "Syrie"
    },
    {
        "id": 161,
        "nationalite": "Sénégalaise",
        "libelle": "Sénégal"
    },
    {
        "id": 176,
        "nationalite": "Tadjike",
        "libelle": "Tadjikistan"
    },
    {
        "id": 178,
        "nationalite": "Tanzanienne",
        "libelle": "Tanzanie"
    },
    {
        "id": 177,
        "nationalite": "Taïwanaise",
        "libelle": "Taïwan"
    },
    {
        "id": 179,
        "nationalite": "Tchadienne",
        "libelle": "Tchad"
    },
    {
        "id": 180,
        "nationalite": "Thaïlandaise",
        "libelle": "Thaïlande"
    },
    {
        "id": 181,
        "nationalite": "Timoraise",
        "libelle": "Timor oriental"
    },
    {
        "id": 182,
        "nationalite": "Togolaise",
        "libelle": "Togo"
    },
    {
        "id": 183,
        "nationalite": "Tongienne",
        "libelle": "Tonga"
    },
    {
        "id": 184,
        "nationalite": "Trinidadienne",
        "libelle": "Trinité-et-Tobago"
    },
    {
        "id": 185,
        "nationalite": "Tunisienne",
        "libelle": "Tunisie"
    },
    {
        "id": 186,
        "nationalite": "Turkmène",
        "libelle": "Turkménistan"
    },
    {
        "id": 187,
        "nationalite": "Turque",
        "libelle": "Turquie"
    },
    {
        "id": 188,
        "nationalite": "Tuvaluane",
        "libelle": "Tuvalu"
    },
    {
        "id": 189,
        "nationalite": "Ukrainienne",
        "libelle": "Ukraine"
    },
    {
        "id": 190,
        "nationalite": "Uruguayenne",
        "libelle": "Uruguay"
    },
    {
        "id": 191,
        "nationalite": "Vanuataise",
        "libelle": "Vanuatu"
    },
    {
        "id": 192,
        "nationalite": "Vénézuélienne",
        "libelle": "Venezuela"
    },
    {
        "id": 193,
        "nationalite": "Vietnamienne",
        "libelle": "Viêt Nam"
    },
    {
        "id": 194,
        "nationalite": "Yéménite",
        "libelle": "Yémen"
    },
    {
        "id": 195,
        "nationalite": "Zambienne",
        "libelle": "Zambie"
    },
    {
        "id": 196,
        "nationalite": "Zimbabwéenne",
        "libelle": "Zimbabwe"
    }
]


export default nationalityCountry;