

import React, { useState, useEffect, useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { accountService } from '../_services';
import { Link, Route } from 'react-router-dom';

import userImage from "./../public/icons/user.jpg";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


export default function TopRight2({ ...props }) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        
        <a  onClick={handleShow}>
                <img className="rounded-circle user" src={userImage}  alt="user"  />
        </a>
        <Offcanvas show={show} onHide={handleClose} {...props}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Profil</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
                <div>
                    <div>
                        <Link to="/account/login" onClick={accountService.logout} className="nav-item nav-link mr-3"> <LogoutIcon color='primary' /> Se deconnecter</Link>
                    </div>
                    <div>
                        <Link to="/profile" className="nav-item nav-link"> <AccountBoxIcon color='primary' /> Mon profil</Link>
                    </div>
                </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
  );
}
