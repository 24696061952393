import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { languageService, alertService } from '../../_services';

function AddEditLanguage({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const [dataLoading, setDataLoading] = useState(false);
    
    const initialValues = {
        languageName: '',
        level : ''
    };

    

    const validationSchema = Yup.object().shape({
        languageName: Yup.string()
            .required('Le nom de la Langue est obligatoire'),
        level: Yup.string()
            .required('Le niveau de la Langue est obligatoire')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createLanguage(fields, setSubmitting);
        } else {
            updateLanguage(id, fields, setSubmitting);
        }
    }

    function createLanguage(fields, setSubmitting) {
        fields.level = parseInt(fields.level);
        languageService.createLanguage(fields)
            .then(() => {
                alertService.success('Langue ajoutée avec succès', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    function updateLanguage(id, fields, setSubmitting) {
        fields.languageId = parseInt(id);
        fields.level = parseInt(fields.level);
        
        
        languageService.updateLanguage(id, fields)
            .then(() => {
                alertService.success('Langue modifiée', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const loadingLanguageUpdate = (setFieldValue) => {
        if (!isAddMode) {
            // get Language and set form fields
            languageService.getLanguageById(id).then(language => {
                const fields = ['languageName','level'];
                fields.forEach(field => setFieldValue(field, language[field], false));
            });
        }
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {

                if(!isAddMode && !dataLoading){
                    loadingLanguageUpdate(setFieldValue);
                    setDataLoading(true);
                }
                
                return (
                    <Form>
                        <h1>{isAddMode ? "Ajout d'une Langue" : "Modification d'une Langue"}</h1>
                        
                  
                        <div className="form-group col-5">
                            <label>Nom</label>
                            <Field name="languageName" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                            <ErrorMessage name="languageName" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group col-3">
                            <label>Niveau</label>
                            <Field name="level" as="select" className={'form-control' + (errors.level && touched.level ? ' is-invalid' : '')}>
                                <option value=""></option>
                                <option value="0">A1</option>
                                <option value="1">A2</option>
                                <option value="2">B1</option>
                                <option value="3">B2</option>
                                <option value="4">C1</option>
                                <option value="5">C2</option>
                            </Field>
                            <ErrorMessage name="level" component="div" className="invalid-feedback" />
                        </div>
                        
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Enregistrer
                            </button>
                            <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Annuler</Link>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export { AddEditLanguage };