
import React from 'react';
//import { Link } from 'react-router-dom';
import { candidateService } from '../../_services';



import logo2 from './../../public/icons/ax_logo.png';


//import config from 'config';
const baseUrl = `${process.env.REACT_APP_APIURL}/api/Candidate`;

class TableComponent extends React.Component {
    constructor() {
        super();
        this.state = { 
            data: []
        };
    }
    
    async componentDidMount() {

        await fetch(`${baseUrl}/getById/${this.props.idCandidate}`)
                .then( response =>{
                if(response.ok) return response.json();
                throw response;
                })
                .then( datas => {
                    this.setState({ data: datas });
                    //setCandidate(data);
                    //setLoading(false);
                })
                .catch( error => {
                    console.error("Error loading data", error);
                    //setLoading(false);
                });

        // const response = await fetch(`https://api.coinmarketcap.com/v1/ticker/?limit=10`);
        // const json = await response.json();
        // this.setState({ data: json });
    }
    render() {
        
        var {
            candidateId,
            candidateName,
            firstName,
            civility,
            email,
            nationality,
            adress,
            payWish,
            contractCandidate,
            phone,
            dateOfBirthday,
            postalCode,
            city,
            roleWish,
            driverLicence,
            infoCandidate,
            keepInTouch,
            profil,
            mobility,
            competences	,
            experiences	,
            certifications	,
            formations	,
            documents,
            languages	,
            reports	,
            referents,
            interviews	
        } = { ...this.state.data};
        return (
            this.state.data &&

            <div>
                    <div> 
                        <header>
                            <img src={logo2} className="rounded mx-auto d-block mt-5 logo_ax" alt="l'agent x" />
                        </header>
                        <br />
                        <h2 className='d-flex justify-content-center dctTitles'>DOSSIER DE COMPÉTENCES</h2>
                        <div>
                            <div className='d-flex justify-content-center'>
                                <strong>
                                    {candidateService.convertNameCandidate( this.state.data?.firstName, this.state.data?.candidateName ) }
                                </strong> - {roleWish}
                            </div>
                            <div className='d-flex justify-content-center' >
                                {infoCandidate?.experienceLevel} d'expérience
                            </div>

                        </div>
                        <br />
                        <br />

                        <div className='d-flex flex-row m-3 justify-content-center'>

                            <div>
                                
                                <div>
                                    <h4> <span className='dctTitles'>FORMATIONS:</span> </h4>
                                    <ul>
                                    {
                                        formations && formations.map( (formation, index) =>
                                            <li key={index}>
                                                {formation.grade} { formation.diploma } / { formation.yearOfObtain }
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <br />
                                <div>
                                    <h4 className='dctTitles'>CERTIFICATIONS:</h4>
                                    <ul>
                                    {
                                        certifications && certifications.map( (certification, index) =>
                                            <li key={index}> { certification.certificationName }  { certification.certificationYear } ({certification.certificationDomain}) </li>
                                        )}
                                        
                                    </ul>
                                </div>
                                <br />
                                <div>
                                    <h4 className='dctTitles'>LANGUES</h4>
                                        <ul>
                                            {languages && languages.map((comp, index) => 
                                                <li key={index}> {comp.languageName} { candidateService.convertLanguage (comp.level )} </li>)  }
                                        </ul>
                                </div>
                                <div>
                                    <h4 className='dctTitles'>COMPÉTENCES GLOBALES</h4>
                                    <ul>
                                        {competences && competences.filter(x => x.competenceType === 2)
                                                                .map((comp, index) => 
                                                                        <li key={index}> {comp.competenceName} </li>)  }
                                    </ul>
                                </div>
                            </div>
                            <div className='detailsCandidateForm'>
                                <h4 className='dctTitles'>TECHNOLOGIES MAITRISÉES:</h4>
                                <div>
                                    <strong className='dctTitles'>Langages</strong>
                                    <ul>
                                        {competences && competences.filter(x => x.competenceType === 0)
                                                                .map((comp, index) => 
                                                                        <li key={index}> {comp.competenceName} </li>)  }
                                    </ul>
                                </div>
                                <div>
                                    <strong className='dctTitles'>Autres compétences Fonctionnelles/ Methodologiques</strong>
                                    <br />
                                    <ul>
                                    {competences && competences.filter(x => x.competenceType === 1)
                                                                    .map((comp, index) => 
                                                                            <li key={index}> {comp.competenceName} </li>)  }
                                    </ul>
                                </div>

                                <div>
                                    <h4 className='dctTitles'>SOFT SKILLS </h4>
                                </div>

                            </div>
                        </div>

                        <div>
                            <h4 className='dctTitles'>PARCOURS PROFESSIONNEL</h4>
                            <ul>
                                {
                                    experiences && experiences.map( (experience, index) =>
                                        <li key={index}> {experience.entreprise} : {experience.fonction} - {experience.mission} du {experience.dateBegin} au {experience.dateEnd} </li>
                                    )
                                }
                            </ul>
                        </div>



                    </div>
                    
                    


            </div>
        );
    }
}

export default TableComponent;