import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


import { accountService, alertService } from '../_services';

function ResetPassword({ history }) {
    const TokenStatus = {
        Validating: 'Validating',
        Valid: 'Valid',
        Invalid: 'Invalid'
    }

    
    const [token, setToken] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

    const location = useLocation();

    useEffect(() => {
        const { token } = queryString.parse(location.search);

        // remove token from url to prevent http referer leakage
        history.replace(location.pathname);

        accountService.validateResetToken(token)
            .then(() => {
                setToken(token);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch(() => {
                setTokenStatus(TokenStatus.Invalid);
            });
    }, []);

    const [passwordType, setPasswordType] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");

    const togglePassword =()=>{
        if(passwordType==="password")
        {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
        }

        const togglePassword2 =()=>{
        if(passwordType2==="password")
        {
            setPasswordType2("text")
            return;
        }
        setPasswordType2("password")
    }

    function getForm() {
        const initialValues = {
            password: '',
            confirmPassword: ''
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(8, 'le mot de passe doit avoir 8 caractères minimum')
                .required('Le mot de passe est obligatoire'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'mot de passe incorrect')
                .required('La confirmation du mot de passe est obligatoire'),
        });

        function onSubmit({ password, confirmPassword }, { setSubmitting }) {
            alertService.clear();
            accountService.resetPassword({ token, password, confirmPassword })
                .then(() => {
                    alertService.success('Mot de passe modifié avec succès, vous pouvez vous connecter maintenant', { keepAfterRouteChange: true });
                    history.push('login');
                })
                .catch(error => {
                    setSubmitting(false);
                    alertService.error(error);
                });
        }

        

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group d-flex flex-row mt-3 ">
                            <div className="form-group col-10">
                                <label>Mot de passe</label>
                                <Field name="password" type={passwordType} className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group  mt-4">
                                    <button className="btn btn-outline-primary" type="button" onClick={togglePassword}>
                                        { passwordType==="password"? <VisibilityIcon variant="primary" /> : <VisibilityOffIcon variant="primary" /> }
                                    </button>
                            </div>
                        </div>

                        <div className="form-group d-flex flex-row mt-3 ">
                            <div className="form-group col-10">
                                <label>Confirmation du mot de passe</label>
                                <Field name="confirmPassword" type={passwordType2} className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group  mt-4">
                                <button className="btn btn-outline-primary" type="button" onClick={togglePassword2}>
                                    { passwordType==="password"? <VisibilityIcon variant="primary" /> : <VisibilityOffIcon variant="primary" /> }
                                </button>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Modifier
                                </button>
                                <Link to="login" className="btn btn-link">Annuler</Link>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    function getBody() {
        switch (tokenStatus) {
            case TokenStatus.Valid:
                return getForm();
            case TokenStatus.Invalid:
                return <div>La validation du jeton a échoué, si le jeton a expiré, vous pouvez en obtenir un nouveau sur la page <Link to="forgot-password">mot de passe oublié</Link></div>;
            case TokenStatus.Validating:
                return <div>Validation du jeton...</div>;
        }
    }

    return (
        <div>
            <h3 className="card-header">Changer de mot de passe</h3>
            <div className="card-body">{getBody()}</div>
        </div>
    )
}

export { ResetPassword }; 