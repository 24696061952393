import React from 'react';
import { Navbar } from '../../_components/Navbar';
import { Route, Switch } from 'react-router-dom';
import { AddEditCandidate } from './AddEditCandidate';
import { Candidates } from './Candidates';
import { DetailsCandidate } from './DetailsCandidate';
//import BasicDocument from "./BasicDocument";
import ExportPdfComponent from "./ExportPdfComponent";


function Candidate({ match }) {
    const { path } = match;
    return (
        <>
            <Navbar />
            <div className="container">
                {/* <Route exact path={path} component={Candidates} /> */}
                <Switch>
                    <Route exact path={path} component={Candidates} />
                    <Route path={`${path}/add`} component={AddEditCandidate} />
                    <Route path={`${path}/update/:id`} component={AddEditCandidate} />
                    <Route path={`${path}/getById/:id`} component={DetailsCandidate} />
                    <Route path={`${path}/getSkillsCandidate/:id`} component={ExportPdfComponent} />

                </Switch>
                
            </div>
        </>
    )
}

export  {Candidate} ;