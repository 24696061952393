import React, { useEffect } from 'react';
import { Navbar } from '../_components/Navbar';

import { accountService } from '../_services';
import {useHistory } from 'react-router-dom';

function Home() {
    const user = accountService.userValue;
    const history = useHistory();
    
    useEffect(() => {
        if (user === null){
            history.push("account/login");
        }
    }, [])
    
    return (
        
        <Navbar />
    );
}

export { Home };