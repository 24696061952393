import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { fetchWrapper, history } from '../_helpers';

const competenceSubject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_APIURL}/api/Competence`;

export const competenceService = {
    getAllCompetence,
    getCompetenceById,
    createCompetence,
    updateCompetence,
    deleteCompetence: _delete,
    competence: competenceSubject.asObservable(),
    get competenceValue () { return competenceSubject.value }
};

function getAllCompetence() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}

function getCompetenceById(id) {
    return fetchWrapper.get(`${baseUrl}/getById/${id}`);
}

function createCompetence(params) {
    
    return fetchWrapper.post(`${baseUrl}/add`, params);
}

function updateCompetence(id, params) {
    
    return fetchWrapper.put(`${baseUrl}/update/${id}`, params)
        .then(competence => {
            return competence;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/delete/${id}`)
        .then(x => {
            return x;
        });
}