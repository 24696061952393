import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { profilService, alertService } from '../../_services';

function AddEditProfil({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const [dataLoading, setDataLoading] = useState(false);
    
    const initialValues = {
        name: ''
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Le nom du profil est obligatoire')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createProfil(fields, setSubmitting);
        } else {
            updateProfil(id, fields, setSubmitting);
        }
    }

    function createProfil(fields, setSubmitting) {
        profilService.createProfil(fields)
            .then(() => {
                alertService.success('Profil ajouté avec succès', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    function updateProfil(id, fields, setSubmitting) {
        fields.profilId = parseInt(id);
        
        profilService.updateProfil(id, fields)
            .then(() => {
                alertService.success('Profil modifié', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const loadingProfilUpdate = (setFieldValue) => {
        if (!isAddMode) {
            // get Profil and set form fields
            profilService.getProfilById(id).then(Profil => {
                const fields = ['name'];
                fields.forEach(field => setFieldValue(field, Profil[field], false));
            });
        }
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {
                
                if(!isAddMode && !dataLoading){
                    loadingProfilUpdate(setFieldValue);
                    setDataLoading(true);
                }

                return (
                    <Form>
                        <h1>{isAddMode ? "Ajout d'un Profil" : "Modification d'un Profil"}</h1>
                        <div className="form-row">
                  
                            <div className="form-group col-5">
                                <label>Nom</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>

                        </div>
                        
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Enregistrer
                            </button>
                            <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Annuler</Link>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export { AddEditProfil };