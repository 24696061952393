import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { accountService, alertService } from '../../../_services';



function AddEdit({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const [dataLoading, setDataLoading] = useState(false);
    
    const initialValues = {
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        password: '',
        confirmPassword: ''
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('La fonction est obligatoire'),
        firstName: Yup.string()
            .required('Le Prenom est obligatoire'),
        lastName: Yup.string()
            .required('Le Nom est obligatoire'),
        email: Yup.string()
            .email('Email invalide')
            .required('Email obligatoire'),
        role: Yup.string()
            .required('Le Role est obligatoire'),
        password: Yup.string()
            .concat( ! isAddMode ? null : null) // Yup.string().required('Le mot de passe est obligatoire') : null)
            .min(8, 'Le mot de passe doit avoire 8 caractères minimum'),
        confirmPassword: Yup.string()
            .when('password', (password, schema) => {
                if (password) return schema.required('La confirmation du mot de passe est obligatoire');
            })
            .oneOf([Yup.ref('password')], 'Mot de passe incorrect')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createUser(fields, setSubmitting);
        } else {
            updateUser(id, fields, setSubmitting);
        }
    }

    function createUser(fields, setSubmitting) {
        accountService.create(fields)
            .then(() => {
                alertService.success('Utilisateur ajouté avec succès', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    function updateUser(id, fields, setSubmitting) {
        accountService.update(id, fields)
            .then(() => {
                alertService.success('La modification a été effectuée', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const modifyComponent = (setFieldValue) => {
        if (!isAddMode) {
            // get user and set form fields
            accountService.getById(id).then(user => {
                const fields = ['title', 'firstName', 'lastName', 'email', 'role'];
                fields.forEach(field => setFieldValue(field, user[field], false));
            });
        }
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {

                if(!isAddMode && !dataLoading){
                    modifyComponent(setFieldValue);
                    setDataLoading(true);
                }
                
                
                return (
                    <Form>
                        <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1>
                        <div className="form-row">
                            
                            <div className="form-group col-5">
                                <label>Prenom</label>
                                <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-5">
                                <label>Nom</label>
                                <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group col">
                                <label>Fonction</label>
                                <Field name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')}>

                                </Field>
                                <ErrorMessage name="title" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-7">
                                <label>Email</label>
                                <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>Role</label>
                                <Field name="role" as="select" className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')}>
                                    <option value=""></option>
                                    <option value="Admin">Admin</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Rh">RH</option>
                                    <option value="Client">Client</option>
                                    <option value="Guest">Guest</option>
                                </Field>
                                <ErrorMessage name="role" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        {!isAddMode &&
                            <div>
                                <h3 className="pt-3">Modifier mot de passe</h3>
                                <p>Laissez vide pour garder le même mot de passe</p>
                            </div>
                        }

                        {!isAddMode && 
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>Mot de passe</label>
                                    <Field name="password" type="text" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group col">
                                    <label>Confirmation mot de passe</label>
                                    <Field name="confirmPassword" type="text" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                        }
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Enregistrer
                            </button>
                            <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Annuler</Link>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export { AddEdit };