import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { fetchWrapper, history } from '../_helpers';

const candidateSubject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_APIURL}/api/Candidate`;

export const candidateService = {
    getAllCandidate,
    getCandidateById,
    createCandidate,
    updateCandidate,
    convertContractType,
    convertLanguage,
    convertGender,
    stylingKeepBlack,
    choiceLocation,
    experienceLevel,
    mobilityCustom,
    diponibilityDate,
    insertWhiteSpace,
    convertNameCandidate,
    deleteCandidate: _delete,
    candidate: candidateSubject.asObservable(),
    get candidateValue () { return candidateSubject.value }
};

function getAllCandidate() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}

function getCandidateById(id) {
    return fetchWrapper.get(`${baseUrl}/getById/${id}`);
}

function createCandidate(params) {
    
    return fetchWrapper.post(`${baseUrl}/add`, params);
}

function updateCandidate(id, params) {
    
    return fetchWrapper.put(`${baseUrl}/update/${id}`, params)
        .then(candidate => {
            return candidate;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/delete/${id}`)
        .then(x => {
            return x;
        });
}

function convertContractType(contract){
    switch (contract) {
        case 0:
            return "CDI";
        case 1:
            return "CDD";
        case 2:
            return "Freelance"
        case 3:
            return "Stage"
        case 4:
            return "Alternance"
        case 5:
            return "Autre"
        default:
            return "Inconnu"
            
    }
}

function convertLanguage(language){
    switch (language) {
        case 0:
            return "Courant";
        case 1:
            return "Courant";
        case 2:
            return "Scolaire";
        case 3:
            return "Scolaire";
        case 4:
            return "Professionnel";
        case 5:
            return "Bilingue";
        default:
            return "Inconnu"
            
    }
}

function convertGender(gender){
    switch (gender) {
        case "M":
            return "Monsieur";
        case "F":
            return "Madame";
        default:
            return "Inconnu"
            
    }


}
function choiceLocation(location, adress, postalCode, city) {
    if ( adress === "" ) return location;
    return `${adress}, ${postalCode} ${city} `;
}

function experienceLevel(experience) {
    if ( experience !== "" && experience !== null ) return `${experience} d'expérience`;
    return "";
}

function mobilityCustom(mobility) {
     
    let mobilityResult = "";
    
    if ( mobility?.mobilityCountry !== "" &&  mobility?.mobilityCountry !== null ) mobilityResult += mobility?.mobilityCountry;

    if ( mobility?.mobilityRegion !== "" &&  mobility?.mobilityRegion !== null ) mobilityResult += ` en region ${mobility?.mobilityRegion}`;

    if ( mobility?.mobilityDetails !== "" &&  mobility?.mobilityDetails !== null ) mobilityResult += ` à ${mobility?.mobilityDetails}`;
    
    return mobilityResult;
}

function diponibilityDate(disponibility) {
    if ( disponibility !== "" &&  disponibility !== null ) return `Dispo : ${disponibility}`
    
    return "";
}

function insertWhiteSpace() {
    return <span className='competenceForm2'>experience</span>
    
}

function stylingKeepBlack() {
    var element = document.getElementById("blacklist");
    element.style.backgroundColor = '#0855C9';
    element.style.marginLeft = "10px";

    
}

function convertNameCandidate(firstName, lastName){
    return firstName?.substring(0,1).toUpperCase()+"."+lastName?.substring(0,1).toUpperCase()
}


const stylingKeep = (id) => {
    var dataLoading = true;
    if(dataLoading){
        var listKeep = ["blacklist","negative", "medium", "normal","positive"];
       
        
        listKeep.map(x =>
            { 
                var elementList =  document.getElementById(x);
                if ( x != id && elementList.classList.contains("keep3")){

                    elementList.className = elementList.className.replace( /(?:^|\s)keep3(?!\S)/g , '' )
                
                }
            }
        );
        

        var element = document.getElementById(id);
        element.className += " keep3";
        // element.style.backgroundColor = 'red';
        // element.style.marginLeft = "10px";
        // element.style.color = "white";
    }
    
}
