import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData.js';
import '../public/css/Navbar.css';
import { IconContext } from "react-icons";
//import userImage from "./../public/icons/user.jpg";
//import * as MdIcons from 'react-icons/md';
import * as FiIcons from 'react-icons/fi';
import * as MdIcons from 'react-icons/md';
import TopRight2 from './TopRight2.jsx';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  

  let iconStyles = { color: "black" };
  return (
    <>
      <IconContext.Provider value={{ className:"regular_icons"}}>
        <div>
          <div className='navbar'>

            <div className="navbar-bar">
              <Link to='#' className='menu-bars'>
                <FaIcons.FaBars style={iconStyles} onClick={showSidebar} />
              </Link>
            </div>
            
            <div className="nav_composant">
            
              <div className="navbar-icons">
            
                <span className="navbar-switch-mode">
                  <MdIcons.MdDarkMode /> 
                  <MdIcons.MdLightMode />
                </span>
                <span className="navbar-mail">
                <FiIcons.FiMail />
                </span>
                <span className="navbar-notification">
                <MdIcons.MdOutlineNotificationsNone />
                </span>
            
              </div>

              <div className="navbar-avatar">
                  <TopRight2 placement="end" />
              </div>
            
            </div>
          </div>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' >
              <li className='navbar-toggle'>
                <Link to='#' className='menu-bars' onClick={showSidebar}>
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
}

export {Navbar};