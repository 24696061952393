import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { accountService, alertService } from '../_services';

function Register({ history }) {
    const initialValues = {
        title: '',
        firstName: '',
        lastName: '',
        role:'',
        email: '',
        // password: '',
        // confirmPassword: '',
        acceptTerms: false
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('le titre est obligatoire'),
        firstName: Yup.string()
            .required('Le prenom est obligatoire'),
        lastName: Yup.string()
            .required('le nom est obligatoire'),
        email: Yup.string()
            .email('Email invalide')
            .required('Email obligatoire'),
        // password: Yup.string()
        //     .min(8, 'le mot de passe doit avoir 8 caractères minimum')
        //     .required('mot de passe obligatoire'),
        // confirmPassword: Yup.string()
        //     .oneOf([Yup.ref('password'), null], 'mot de passe non conforme')
        //     .required('Confirmation mot de passe obligatoire'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept Terms & Conditions obligatoire')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        //console.log(fields);
        accountService.register(fields)
            .then(() => {
                alertService.success('Creation de votre compte effectué, Veuillez verifier votre mail et suivre les instructions', { keepAfterRouteChange: true });
                history.push('login');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <h3 className="card-header header-connection">Creation Compte</h3>
                    <div className="card-body">
                        <div className="form-row mt-3">
                            

                            <div className="form-group col">
                                <label>Prenom</label>
                                <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group col mt-3">
                                <label>Nom</label>
                                <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="form-group col mt-3">
                                <label>Fonction</label>
                                <Field name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                                <ErrorMessage name="title" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group mt-3">
                            <label>Email</label>
                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group col mt-3">
                                <label>Role</label>
                                <Field name="role" as="select" className={'form-control' + (errors.role && touched.role ? ' is-invalid' : '')}>
                                    <option value=""></option>
                                    <option value="Admin">Admin</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Rh">RH</option>
                                    <option value="Client">Client</option>
                                    <option value="Guest">Guest</option>
                                </Field>
                                <ErrorMessage name="role" component="div" className="invalid-feedback" />
                        </div>
                        
                        <div className="form-group form-check mt-3">
                            <Field type="checkbox" name="acceptTerms" id="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                            <label htmlFor="acceptTerms" className="form-check-label">Accept Terms & Conditions</label>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group mt-3">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Valider
                            </button>
                            <Link to="login" className="btn btn-link">Annuler</Link>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { Register }; 