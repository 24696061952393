import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Navbar } from '../../_components/Navbar';
import { Overview } from './Overview';
import { Users } from './users/Index';

function Admin({ match }) {

    const { path } = match;

    return (
        <>
            <Navbar />
            <div className="container">
                <Switch>
                    <Route exact path={path} component={Overview} />
                    <Route path={`${path}/users`} component={Users} />
                </Switch>
            </div>
            
        </>
    );
}

export { Admin };