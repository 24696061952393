import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { languageService } from '../../_services';
import * as FaIcons from 'react-icons/fa';


function Languages({ match }) {
    const { path } = match;
    const [languages, setLanguages] = useState(null);

    useEffect(() => {
        languageService.getAllLanguage().then(x => setLanguages(x));
    }, []);

    function deleteLanguage(id) {
        setLanguages(languages.map(x => {
            if (x.languageId === id) { x.isDeleting = true; }
            
            return x;
        }));
        // style={{ fill: 'black' }}
        languageService.deleteLanguage(id).then(() => {
            setLanguages(languages => languages.filter(x => x.languageId !== id));
        });

    }

    function levelToString(level){
        switch (level){
            case 0 : return "A1";
            case 1 : return "A2";
            case 2 : return "B1";
            case 3 : return "B2";
            case 4 : return "C1";
            case 5 : return "C2";

        }
    }

    return (
        <div>
            <h2 className="m-3">Liste des Langues</h2>
            <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2 rounded-pill addBtn"> <i> <FaIcons.FaPlus /> </i> Ajouter une langue</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Id</th>
                        <th style={{ width: '70%' }}>Langue</th>
                        <th style={{ width: '10%' }}>Niveau</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {languages && languages.filter( k  => k.languageName )
                    .map(language =>
                        <tr key={language.languageId}>
                            <td> {language.languageId}</td>
                            <td>{language.languageName}</td>
                            <td>{levelToString(language.level)}</td>
                            
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`${path}/update/${language.languageId}`} className=" mr-1 editBtn"> <i> <FaIcons.FaEdit  size={27} /> </i> </Link>
                                <Link to="/language" onClick={() => deleteLanguage(language.languageId)} className="ml-1 deleteBtn"  disabled={language.isDeleting}>
                                    {language.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span><FaIcons.FaTrash size={25} /></span>
                                    }
                                </Link>
                            </td>
                        </tr>
                    )}
                    {!languages &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

export { Languages };