import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { candidateService } from '../../_services';
//import config from 'config';

import contractTypeImage from "./../../public/icons/contract2.png";
import * as FaIcons from 'react-icons/fa';
import FileUpload from './FileUpload';
import FileDownload from './FileDownload';
//import { Formik, Field, Form, useFormik, ErrorMessage, FieldArray } from 'formik';

//import { accountService } from '../../_services';
// import icons 
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'; // date
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined'; // Diplome 
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';

import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined'; // Un autre education
import ModelTrainingIcon from '@mui/icons-material/ModelTraining'; 
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
const baseUrl = `${process.env.REACT_APP_APIURL}/api/Candidate`;





function DetailsCandidate({ match }) {
    
    const { id } = match.params;
    const [candidate, setCandidate] = useState({});
    const [loading, setLoading] = useState(false);
  
    async function getCandidate() {
        
        setLoading(true);
        await fetch(`${baseUrl}/getById/${id}`)
                .then( response =>{
                  if(response.ok) return response.json();
                  throw response;
                })
                .then( data => {
                    setCandidate(data);
                    setLoading(false);
                })
                .catch( error => {
                    console.error("Error loading data", error);
                    setLoading(false);
                });
    }
    
    var {
        candidateId,
        candidateName,
        firstName,
        civility,
        email,
        nationality,
        adress,
        payWish,
        contractCandidate,
        phone,
        dateOfBirthday,
        postalCode,
        city,
        roleWish,
        driverLicence,
        location,
        infoCandidate,
        keepInTouch,
        profil,
        mobility,
        competences	,
        experiences	,
        certifications	,
        formations	,
        documents,
        languages	,
        reports	,
        referents,
        interviews	
    } = { ...candidate};

    


    useEffect( () => {
        getCandidate();
        
   
    }, []);

    

    return (
        <>
            {
                loading ? (
                    <div>...Data Loading.....</div>
                ) : (
                <div className='mb-5'>
                    
                    <div className='d-flex flex-row m-3'>
                        <div className='m-3'>
                            <strong className='candidateProperties'>CANDIDAT</strong>   {candidate.firstName} {candidate?.candidateName?.toUpperCase() }
                        </div>
                        <div className='m-3'>
                            Keep in touch  <strong>{keepInTouch?.keep}</strong> 
                        </div>
                        <div className='m-3'>
                            <strong>{profil?.profilName}</strong>
                        </div>
                    </div>

                    <hr />
                    
                    <div>
                        <strong>INFORMATIONS PERSONNELLES</strong>
                    </div>

                    <div className='d-flex flex-row'>
                        
                        <div className='d-flex flex-row'>
                            <div>
                                <div className='m-3 '>
                                    
                                    <span> <WcOutlinedIcon color='primary'/>  { candidateService.convertGender (civility)} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <PersonIcon color='primary'/>  {firstName} {candidateName?.toUpperCase()} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <MailOutlineIcon color='primary'/>  {email} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <PhoneIcon color='primary'/>  {phone} </span>
                                </div>
                                
                            </div>
                            {/* { candidateService.choiceLocation(location, candidate.adress, postalCode, city) } */}
                            <div className='detailsCandidateForm'>
                                <div className='m-3'>
                                    <span> <LocationOnIcon color='primary'/> { candidateService.choiceLocation(location, adress, postalCode, city)} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <DateRangeOutlinedIcon color='primary'/>  {dateOfBirthday} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <DriveEtaOutlinedIcon color='primary'/>  {driverLicence} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <EmojiFlagsIcon color='primary'/>  {nationality} </span>
                                </div>
                              
                            </div>
                        </div>
                        
                    </div>

                    <hr />

                    <div>
                        <strong>PROJECTION ET MOBILITÉ</strong>
                    </div>

                    <div className='d-flex flex-row '>
                        
                        <div className='d-flex flex-row '>
                            <div>
                                <div className='m-3'>
                                    <img  src={contractTypeImage}  alt="Card image" className='detailsCandidateImage' /> Type de Contrat : { candidateService.convertContractType(contractCandidate) } 
                                </div>

                                <div className='m-3'>
                                    <span> <ModelTrainingIcon color='primary'/> Rôle souhaité : {roleWish}  </span>
                                </div>

                                <div className='m-3'>
                                    <span> <DateRangeOutlinedIcon color='primary'/> Disponibilité :  {infoCandidate?.disponibility} </span>
                                </div>

                                <div className='m-3'>
                                    <span> <CenterFocusStrongIcon color='primary'/> Client ciblé :  {infoCandidate?.clientFocused} </span>
                                </div>
                                
                            </div>
                            <div className='detailsCandidateForm'>
                                <div className='m-3'>
                                    <span> <LocationOnIcon color='primary'/>  { candidateService.experienceLevel (infoCandidate?.experienceLevel)} </span>
                                </div>
                                <div className='m-3'>
                                    <span> <AttachMoneyOutlinedIcon color='primary'/>  {payWish} € brut/an</span>
                                </div>
                                <div className='m-3'>
                                    <span> <DriveEtaOutlinedIcon color='primary'/> Mobilité : { candidateService.mobilityCustom(mobility) } </span>
                                </div>
                                
                            </div> 
                        </div>
                        
                    </div>


                    <hr />

                    <div>
                        <strong>COMPÉTENCES, LANGUES</strong>
                    </div>

                    <div className='d-flex flex-row'>
                        
                        <div className='d-flex flex-row mt-3'>
                            <div>
                                <strong>Technique/Outil</strong>
                                <div className='m-3'>
                                    {competences && competences.filter(x => x.competenceType === 0)
                                                               .map( (comp, index) => 
                                                                    <span key={index}> {comp.competenceName} </span>)  }
                                </div>

                            </div>

                            <div className='detailsCandidateForm'>
                                <strong>Fonctionnelle/Methodologique</strong>
                                <div className='m-3'>
                                    {competences && competences.filter(x => x.competenceType === 1)
                                                                .map((comp, index) => 
                                                                        <span key={index}> {comp.competenceName} </span>)  }
                                </div>
                                
                            </div>
                        </div>

                        
                        
                    </div>

                    <div >
                        <strong>Compétences linguistiques</strong>
                        <div className='m-3 '>
                            {languages && languages.map((comp, index) => 
                                                            <span key={index}> {comp.languageName} { candidateService.convertLanguage (comp.level )} </span>)  }
                        </div>

                    </div>

                    <hr />

                    <div>
                        <strong>FORMATIONS</strong>
                    </div>

                    <div>
                        {
                            formations && formations.map( (formation, index) =>
                                <div key={index} className='mt-2'>
                                    <div>
                                        <strong> Formation{index+1} </strong>
                                    </div>

                                    <div className='d-flex flex-row'>
                                        <div>
                                            <div className='m-3 '>
                                                
                                                <span> <SchoolOutlinedIcon color='primary'/> Diplôme : { formation.diploma } </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <CastForEducationOutlinedIcon color='primary'/> Niveau : { formation.grade } </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <DateRangeOutlinedIcon color='primary'/> Année : { formation.yearOfObtain } </span>
                                            </div>
                                            
                                        </div>
                                        <div className='detailsCandidateForm'>
                                            <div className='m-3'>
                                                <span> <DomainOutlinedIcon color='primary'/> Etablissement : {formation.establishment} </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <WorkspacePremiumOutlinedIcon color='primary'/> Mention : {formation.mention} </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <SchoolOutlinedIcon color='primary'/> Specialité {formation.speciality} </span>
                                            </div>
                                            
                                        </div>

                                        
                                    </div>
                        
                                    
                                </div>

                            )
                        }
                    </div>

                    
                    
                    <hr />

                    <div>
                        <strong>EXPÉRIENCES</strong>
                    </div>

                    <div>
                        {
                            experiences && experiences.map( (experience, index) =>
                                <div key={index} className='mt-2'>
                                    <div className='mt-3'>
                                        <strong> Expérience{index+1} </strong>
                                    </div>

                                    <div className='d-flex flex-row'>
                                        <div>
                                            <div className='m-3 '>
                                                
                                                <span> <CorporateFareOutlinedIcon color='primary'/> Entreprise : { experience.entreprise } </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <WorkOutlineOutlinedIcon color='primary'/> Fonction : { experience.fonction } </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <BookOnlineOutlinedIcon color='primary'/> Mission : { experience.mission } </span>
                                            </div>
                                            <div className='col-12 m-3'>
                                                <span> <InsertCommentOutlinedIcon color='primary'/> Commentaire : { experience.experienceComment } </span>
                                            </div>
                                        </div>
                                        <div className='detailsCandidateForm'>
                                            <div className='m-3'>
                                                <span> <DateRangeOutlinedIcon color='primary'/> Date de début : {experience.dateBegin} </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <DateRangeOutlinedIcon color='primary'/> Date de fin : {experience.dateEnd} </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <PersonOutlineOutlinedIcon color='primary'/> Reférence : {experience.reference} </span>
                                            </div>
                                            
                                        </div>

                                        
                                    </div>
                        
                                    
                                </div>

                            )
                        }
                    </div>

                    <hr />

                    <div>
                        <strong>CERTIFICATIONS</strong>
                    </div>

                    <div>
                        {
                            certifications && certifications.map( (certification, index) =>
                                <div key={index} className='mt-2'>
                                    <div className='mt-3'>
                                        <strong> Certification{index+1} </strong>
                                    </div>

                                    <div className='d-flex flex-row'>
                                        <div>
                                            <div className='m-3 '>
                                                
                                                <span> <WorkspacePremiumOutlinedIcon color='primary'/> Nom : { certification.certificationName } </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <DateRangeOutlinedIcon color='primary'/> Année d'obtention : { certification.certificationYear } </span>
                                            </div>
                                            
                                            <div className='col-12 m-3'>
                                                <span> <InsertCommentOutlinedIcon color='primary'/> Commentaire : { certification.certificationComment } </span>
                                            </div>
                                        </div>
                                        <div className='detailsCandidateForm'>
                                            <div className='m-3'>
                                                <span> <CorporateFareOutlinedIcon color='primary'/> Organisme : {certification.organization} </span>
                                            </div>
                                            <div className='m-3'>
                                                <span> <ModelTrainingIcon color='primary'/> Domaine : {certification.certificationDomain} </span>
                                            </div>
                                            
                                            
                                        </div>

                                        
                                    </div>
                        
                                    
                                </div>

                            )
                        }
                    </div>

                    <br />
                    <hr />
                    <FileUpload idCandidate={id}/>

                    <br />
                    <hr />
                    <FileDownload idCandidate={id}/>
                    <br />
                    <hr />
                    <br />
                    <div>
                        <span className='mr-5'>
                            <Link to={`../update/${candidateId}`} className=" mr-1 editBtn"> <i> <FaIcons.FaEdit  size={27} /> </i> </Link>
                        </span>
                        <span>
                            <Link to='..' className="btn btn-secondary">Retour</Link>
                        </span>
                    </div>
                    
                    

                </div>

                
            )}
        </>
    );
   
    
}

export { DetailsCandidate };