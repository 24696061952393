import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';

import { Role } from '../_helpers/index';
import { accountService } from '../_services/index';
import { Nav, PrivateRoute, Alert } from '../_components/index';
import { Home } from '../home/Index';
import { ProfilUser } from '../profile/Index';
import { Admin } from '../pages/admin/Index';
import { Account } from '../account/Index';
import { Dashboard } from '../pages/dashboard/Index';
import { Candidate } from '../pages/candidate/Index';
import { ProfilCandidate } from '../pages/profilCandidate/Index';
import { Interview } from '../pages/interview/Index';
import { Language } from '../pages/language/Index';
import { Competence } from '../pages/competence/Index';


function App() {
    let {pathname}  = useLocation();
    //const location = useLocation();
    const [user, setUser] = useState({});
    

    const history = useHistory();

    useEffect(() => {
        const subscription = accountService.user.subscribe(x => setUser(x));
        
        return subscription.unsubscribe;
        
    }, []);

    



    return (

        <div className = { 'app-container' + (user && ' bg-light') }> 
            
            <Alert /> 
            
            <Switch >
                {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
                <Route exact path = "/" component = { Home } /> 
                <Route path = "/dashboard" component = { Dashboard } /> 
                <Route path = "/interviews" component = { Interview } /> 
                <Route path = "/candidates" component = { Candidate } /> 
                <Route path = "/profil" component = { ProfilCandidate } /> 
                <Route path="/account" component={Account} />
                <Route path = "/language" component = { Language } /> 
                <Route path = "/competence" component = { Competence } /> 
                <PrivateRoute path="/admin" roles={[Role.Admin]} component={Admin} /> 
                <Route path="/profile" component={ProfilUser} />
                {/* <Redirect from="/" to="account/login" /> */}
                {/* <Redirect from="*" to="/" /> */}
                {/* {console.log("Mon chemin : ", pathname) } */}
                
    
            </Switch>
        </div >
    );
}

export { App };