import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//import ReactSession from 'react-client-session';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



import { accountService, alertService } from '../_services';

function Login({ history, location }) {
    const initialValues = {
        email: '',
        password: ''
    };




    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("l'email est invalide")
            .required("l'email est obligatoire"),
        password: Yup.string().required("Le mot de passe est obligatoire")
    });

    function onSubmit({ email, password }, { setSubmitting }) {
        alertService.clear();
        accountService.login(email, password)
            .then(( response) => {
                //var from = "";  
                if ( response.nbConnection === 1){
                    //console.log("Token : "+response.jwtToken);
                    //from = "/reset-password"; 
                    history.push("/reset-password?token="+response.jwtToken); // JSESSIONID
                }    
                else {
                    
                    var {from} = location.state || { from: { pathname: "/" } }; // destructuration
                    history.push(from);
                }
                //console.log("nombre de connection : "+response.nbConnection);
                
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");


    
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }



    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <h3 className="card-header header-connection">Connexion</h3>
                    <div className="card-body">
                        <div className="form-group">
                            <label>Email</label>
                            <Field name="email" type="text"  className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group d-flex flex-row mt-3 ">
                            <div className="form-group col-11">
                                <label>Mot de passe</label>
                                <Field  name="password" type={passwordType} className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                
                            </div>
                            <div className="form-group  mt-4">
                                <button className="btn btn-outline-primary" type="button" onClick={togglePassword}>
                                    { passwordType==="password"? <VisibilityIcon variant="primary" /> : <VisibilityOffIcon variant="primary" /> }
                                </button>
                            </div>
                        
                        </div>

                        <div className="form-row mt-3">
                            <div className="form-group col">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Se connecter
                                </button>
                                <Link to="register" className="btn btn-link">Créer un compte</Link>
                            </div>
                            <div className="form-group col text-right">
                                <Link to="forgot-password" className="btn btn-link pr-0">Mot de passe oublié?</Link>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { Login }; 