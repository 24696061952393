import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Navbar } from '../_components/Navbar';
import { Details } from './Details';
import { Update } from './Update';

function ProfilUser({ match }) {
    const { path } = match;
    
    return (
        <>
            <Navbar />

            <div className="p-4">
            <div className="container">
                <Switch>
                    <Route exact path={path} component={Details} />
                    <Route path={`${path}/update`} component={Update} />
                </Switch>
            </div>
        </div>
        </>
        
    );
}

export { ProfilUser };