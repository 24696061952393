import React from 'react'
import { Navbar } from '../../_components/Navbar'

function Dashboard() {
    return (
        <>
            <Navbar />
            <div className="navAlign" >
                
                Dashboard
            </div>
        </>
    )
}

export  {Dashboard};
