import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { competenceService } from '../../_services';
import * as FaIcons from 'react-icons/fa';


function Competences({ match }) {
    const { path } = match;
    const [competences, setCompetences] = useState(null);

    useEffect(() => {
        competenceService.getAllCompetence().then(x => setCompetences(x));
    }, []);

    function deleteCompetence(id) {
        setCompetences(competences.map(x => {
            if (x.competenceId === id) { x.isDeleting = true; }
            
            return x;
        }));
        // style={{ fill: 'black' }}
        competenceService.deleteCompetence(id).then(() => {
            setCompetences(competences => competences.filter(x => x.competenceId !== id));
        });

    }

    function competenceTypeToString(level){
        switch (level){
            case 0 : return "Technique/Outil";
            case 1 : return "Fonctionnelle/Methodologique";
        }
    }

    return (
        <div>
            <h2 className="m-3">Liste des Competences</h2>
            <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2 rounded-pill addBtn"> <i> <FaIcons.FaPlus /> </i> Ajouter une competence</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Id</th>
                        <th style={{ width: '70%' }}>Competence</th>
                        <th style={{ width: '10%' }}>Type</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {competences && competences.filter( x => x.competenceName )
                    .map(competence =>
                        <tr key={competence.competenceId}>
                            <td> {competence.competenceId}</td>
                            <td>{competence.competenceName}</td>
                            <td>{ competenceTypeToString(competence.competenceType) }</td>
                            
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`${path}/update/${competence.competenceId}`} className=" mr-1 editBtn"> <i> <FaIcons.FaEdit  size={27} /> </i> </Link>
                                <Link to="/competence" onClick={() => deleteCompetence(competence.competenceId)} className="ml-1 deleteBtn"  disabled={competence.isDeleting}>
                                    {competence.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span><FaIcons.FaTrash size={25} /></span>
                                    }
                                </Link>
                            </td>
                        </tr>
                    )}
                    {!competences &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

export { Competences };