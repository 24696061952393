import React, { useState, useEffect } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import { yellow } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';


const baseUrl = `${process.env.REACT_APP_APIURL}/api/Candidate`;

export default function FileDownload(props) {
    const [files, setFiles] = useState(null);
    //const [loading, setLoading] = useState(false);

    const getFiles = async () =>{
        
        await fetch(`${baseUrl}/file/getFiles/${props.idCandidate}`)
                .then( response =>{
                    if(response.ok) return response.json();
                    throw response;
                })
                .then( datas => {
                    setFiles(datas);
                })
                .catch( error => {
                    console.error("Error loading data", error);
                    
                });

    }


    // const downloadFiles = async (fileName) => {
    //     await fetch(`${baseUrl}/file/download/`+fileName, {
    //         method: 'GET',
    //         headers: {
    //           'Content-Type': 'application/pdf',
    //         },
    //       })
    //             .then( response =>{
    //                 if(response.ok) {
    //                     //response.blob();
    //                     // Create blob link to download
    //                     const url = window.URL.createObjectURL(
    //                         response.url,
    //                     );
    //                     const link = document.createElement('a');
    //                     link.href = url;
    //                     link.setAttribute(
    //                         'download',
    //                         `FileName.pdf`,
    //                     );
                    
    //                     // Append to html link element page
    //                     document.body.appendChild(link);
                    
    //                     // Start download
    //                     link.click();
                    
    //                     // Clean up and remove the link
    //                     link.parentNode.removeChild(link);

    //                 }

    //                 throw response;
    //             })
                
                
    //             .catch( error => {
    //                 console.error("Error loading data", error);
                    
    //             });
    // }

    const convertFileName = (filename) => {
        const file = filename.split("@");
        return file[2];
    }

    const downloadFiles2 = async (fileName) => {
        await fetch(`${baseUrl}/file/download/`+fileName, {
            method: 'GET'
          })
                .then( response =>{
                    if(response.ok) {
                        window.open(response.url); 
                    }
                    throw response;
                })
                .catch( error => {
                    console.error("Error loading data", error);
                    
                });
    }



    useEffect( () => {
        getFiles();
    }, []);


  return (
    <>
        <div className='container'>

        
            <div className="hstack gap-3">
                <div className="bg-light col-4"> <FolderIcon sx={{ color: yellow[700] , fontSize: 38}} />  <strong>CV</strong>  </div>
                <div className="vr col-4"></div>
                <div className="bg-light col-4 "><FolderIcon sx={{ color: yellow[700] , fontSize: 38}} />  <strong>Compte Rendu</strong></div>
                <div className="vr col-4"></div>
                <div className="bg-light col-4"><FolderIcon sx={{ color: yellow[700] , fontSize: 38}} />  <strong>Autre</strong></div>
            </div>

            {
                files ? (
                    <div className="hstack gap-3">
                        <div className="bg-light col-4">
                            {
                                files && files
                                            .filter(x => x.documentType === 0)
                                            .map((file , index)=>
                                                <p key={index}>
                                                    <span>{ convertFileName(file.documentName)}</span> 
                                                    <span> <i className='iconVisible' onClick={ ()=> downloadFiles2(file.documentName)}> <VisibilityIcon color='primary'/> </i> </span>
                                                </p>
                                                
                                                )
                            }
                        </div>
                        <div className="vr col-4"></div>
                        <div className="bg-light col-4">
                            {
                                files && files
                                            .filter(x => x.documentType === 1)
                                            .map((file , index)=>
                                                <p key={index}>
                                                    <span>{ convertFileName(file.documentName)}</span>
                                                    <span> <i className='iconVisible' onClick={ ()=> downloadFiles2(file.documentName)}> <VisibilityIcon color='primary' /> </i> </span>
                                                </p>
                                                
                                                )
                            }
                        </div>
                        <div className="vr col-4"></div>
                        <div className="bg-light col-4">
                            {
                                files && files
                                            .filter(x => x.documentType === 2)
                                            .map((file , index)=>
                                                <p key={index}>
                                                    <span>{convertFileName(file.documentName)}</span>
                                                    <span> <i className='iconVisible' onClick={ ()=> downloadFiles2(file.documentName)}> <VisibilityIcon color='primary'/> </i> </span>
                                                </p>
                                                
                                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div className="hstack gap-3">
                        <div className="bg-light col-4">Dossier vide</div>
                        <div className="vr col-4"></div>
                        <div className="bg-light col-4">Dossier vide</div>
                        <div className="vr col-4"></div>
                        <div className="bg-light col-4">Dossier vide</div>
                    </div>
                )
            }
        </div>
        
    </>
    
  )
}
