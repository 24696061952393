import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';

import CustomSelect from "./CustomSelect";

import { candidateService, alertService, accountService } from '../../_services';
import { competenceService } from '../../_services';
import { languageService } from '../../_services';



//import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import countryRegion from '../../_helpers/countryRegion';
import nationalityCountry from '../../_helpers/nationalities';
//import languageCountry from '../../_helpers/languageCountry';
import languageOptions from '../../_helpers/languageOptions';




function AddEditCandidate({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    
    

    const initialValues = {
        candidateName: '',
        firstName:'',
        civility: "",
        email: "",
        nationality: "",
        adress: "",
        payWish: "0",
        contractCandidate: 0,
        driverLicence: "",
        phone: "",
        dateOfBirthday: "",
        postalCode:"",
        city:"",
        roleWish:"",
        location:"",
       
        experienceLevel: "",
        disponibility: "",

        keep: "0",

        profilName:'',

        mobilityCountry: "",
        mobilityRegion:"",
        mobilityDetails:"",
        clientFocused:"",

        multiLanguages: [],
        multiTechnics:[],
        multiFonctionnels:[],

        certifications: [
            {
                certificationName:'',
                certificationYear:'',
                organization:'',
                certificationDomain:'',
                certificationComment:'',
            },
        ],

        interviews: [
            {
                interviewer: 0,
                interviewType: 0,
                interviewerName:'',
                interviewDate: '',
            },
        ],

        languages : [
            {
                languageName:'',
                level: 0,
            },
        ],

        // referents: [
        //     {
        //        referentName : '', 
        //     },
        // ],

        // tests : [
        //     {
        //         testName: "",
        //         content: "",
        //         score: 0,
        //         testComment: ""
        //     },
        // ],

        reports : [
            {
                reportTitle:'',
                ReportComment:'',
                reportType: 0,
                
            },
        ],

        formations : [
            {
                diploma:'',
                grade:'',
                yearOfObtain:'',
                establishment:'',
                mention:'',
                speciality:'',
            },
        ],

        

        experiences : [
            {
                entreprise:'',
                fonction:'',
                mission:'',
                dateBegin:'',
                dateEnd:'',
                reference:'',
                experienceComment:'',
                experienceType: 0,
            },
        ],

        competences : [
            {
                competenceName:'',
                competenceType: 0,
            },
        ],
        
    };
    
    // const countries = countries.add() countryRegion.map(country => country.countryName)
    

    const countries = countryRegion.map(function(entry) {
        var countriesList = {};
        countriesList['label'] = entry.countryName;
        countriesList['value'] = entry.countryName;
        return countriesList;
    });

    const nationalities = nationalityCountry.map(function(entry) {
        var nationalitiesList = {};
        nationalitiesList['label'] = entry.nationalite;
        nationalitiesList['value'] = entry.nationalite;
        return nationalitiesList;
    });

    
    // const languagesCountry = languageCountry.map(function(entry) {
    //     var languagesList = {};
    //     languagesList['label'] = entry.name;
    //     languagesList['value'] = entry.name;
    //     return languagesList;
    // });

    // Get region of country 
    const regionsData = [];
    const loadingSpecificRegion = (country) => {
        regionsData.length = 0;
        countryRegion.filter(k => k.countryName === country)
                     .map( actualCountry =>  actualCountry.regions.map( k =>
                        regionsData.push({label: k.name  , value: k.name}
                     ) ));
    }
            
        
    
    
    // Some dummy language data
    

    const validationSchema = Yup.object().shape({
        candidateName: Yup.string()
            .required('Le nom du Candidat est obligatoire'),
        firstName: Yup.string()
            .required('Le prenom du Candidat est obligatoire'),
        civility: Yup.string()
            .required('La civilité du Candidat est obligatoire'),
        email: Yup.string()
            .required("L'email du Candidat est obligatoire"),
        nationality: Yup.string(),
            //.required('La nationalité du Candidat est obligatoire'),
        adress: Yup.string(),
            //.required("L'adresse du Candidat est obligatoire"),
        payWish: Yup.string(),
        contractCandidate: Yup.number()
            .required('Le type de contrat du Candidat est obligatoire'),
        driverLicence: Yup.string(),
            //.required('Le type de contrat du Candidat est obligatoire'),
        phone: Yup.string()
            .required('Le numero de telephone du Candidat est obligatoire'),
        dateOfBirthday: Yup.string(),
        postalCode: Yup.string(),
        city: Yup.string(),
        roleWish: Yup.string(),

        keep: Yup.number(),
        
        experienceLevel: Yup.string(),
        disponibility: Yup.string(),

        profilName: Yup.string(),

        mobilityCountry: Yup.string(),
        mobilityRegion: Yup.string(),
        mobilityDetails: Yup.string(),
        clientFocused: Yup.string()

    });

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
      ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
    }));
      
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          {...props}
        />
      ))(({ theme }) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
    }));
      
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    function onSubmit(fields, { setStatus, setSubmitting }) {
        
        //alert(JSON.stringify(fields, null, 2));
        setStatus();
       
        if (isAddMode) {
            createCandidate(fields, setSubmitting);
        } else {
            updateCandidate(id, fields, setSubmitting);
        }
    }

    function createCandidate(fields, setSubmitting) {
        //alert(JSON.stringify(fields, null, 2));
        fields.keep = parseInt(fields.keep);
        fields.contractCandidate = parseInt(fields.contractCandidate);
        
        fields.createdBy = accountService.userValue.email;
        
        fields.infoCandidateId = 0; // A voir après mais pas necessaire
        fields.keepInTouchId = 0;
        fields.profileId = 0;
        fields.mobilityId = 0;
        
        candidateService.createCandidate(fields)
            .then(() => {
                alertService.success('Candidat ajoutée avec succès', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    function updateCandidate(id, fields, setSubmitting) {
        fields.candidateId = parseInt(id); //
        fields.keep = parseInt(fields.keep);
        fields.contractCandidate = parseInt(fields.contractCandidate);
        
        fields.modifiedBy = accountService.userValue.email;
    
        candidateService.updateCandidate(id, fields)
            .then(() => {
                alertService.success('Candidat modifié', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const [expanded, setExpanded] = useState('panel1');
    const [competences, setCompetences] = useState([]);
    const [languages, setLanguages] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    //const [fieldUpdate, setFieldUpdate] = useState(() => );
    

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const outils = [...competences.filter(k => k.competenceType === 0)];
    const methodologiques = [...competences.filter(k => k.competenceType === 1)];
    const outilsOptions = [];
    const methodologiquesOptions = [];

    outils.map(k => outilsOptions.push({label: k.competenceName, value: k.competenceName}));
    methodologiques.map(k => methodologiquesOptions.push({label: k.competenceName, value: k.competenceName}));
    

    const loadingCandidateUpdate = (setFieldValue) => {
        if (!isAddMode) {
            // get Candidate and set form fields
            candidateService.getCandidateById(id).then(candidate => {
                const fields = ['candidateName', 'firstName', 'civility', 'email', 'nationality',
                                'adress', 'payWish', 'contractCandidate', 'driverLicence',
                                'phone', 'dateOfBirthday', 'postalCode', 'city', 'roleWish', 
                                'experienceLevel', 'disponibility', 'profilName','location',
                                'mobilityCountry', 'mobilityRegion', 'mobilityDetails', 'certifications',
                                'competences', 'experiences', 'languages', 'interviews', 'reports', 'formations'
                                
                                ]; 
                
                                
                fields.forEach(field => setFieldValue(field, candidate[field], false));
                setFieldValue("mobilityCountry", candidate?.mobility?.mobilityCountry , false);
                setFieldValue("mobilityRegion", candidate?.mobility?.mobilityRegion , false);
                setFieldValue("mobilityDetails", candidate?.mobility?.mobilityDetails , false);
                setFieldValue("profilName", candidate?.profil?.profilName , false);
                setFieldValue("keep", candidate?.keepInTouch?.keep.toString(), false);
                
            });
        }
    
        languageService.getAllLanguage().then(x => setLanguages(x));
        
        competenceService.getAllCompetence().then(x => setCompetences(x));
        
    }

    // useEffect(() => {
    //     if (!isAddMode && !dataLoading ) {
    //         loadingCandidateUpdate(setFieldValue);
    //         setDataLoading(true);
    //     } 
    // },[]);

    

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, values, setFieldValue }) => {
                
                if (!isAddMode && !dataLoading ) {
                    loadingCandidateUpdate(setFieldValue);
                    setDataLoading(true);
                } 
                
                return (
                    <div className='mt-5 '>
                        <Form>
                            <h2>{isAddMode ? "COMPTE RENDU D'ENTRETIEN" : "MODIFICATION D'UN CANDIDAT"}</h2>
                            <h4>CANDIDAT</h4>
                            <h5>LE CANDIDAT A-T-IL UN KEEP IN TOUCH ?</h5>
                            <div role="group" aria-labelledby="my-radio-group" className='m-3'>
                            
                                <label id='blacklist' className="text-gray-500 font-bold mr-2 rounded-pill keep3" >
                                    <span className="text-sm keep1"><strong>-1</strong></span>
                                    <Field type="radio" name="keep" checked={values.keep === '-1'} value="-1" className="mr-1 leading-tight keep4" />
                                    
                                </label>

                                <label id='negative' className="text-gray-500 font-bold mr-2 rounded-pill keep3" >
                                    <span className="text-sm keep1"><strong>0</strong></span>
                                    <Field type="radio" name="keep" checked={values.keep === '0'} value="0" className="mr-1 leading-tight keep4" />
                                    
                                </label>

                                <label id='medium' className="text-gray-500 font-bold mr-2 rounded-pill keep3" >
                                    <span className="text-sm keep1"><strong>1</strong></span>
                                    <Field type="radio" name="keep" checked={values.keep === '1'} value="1" className="mr-1 leading-tight keep4"/>
                                    
                                </label>

                                <label id='normal' className="text-gray-500 font-bold mr-2 rounded-pill keep3" >
                                    <span className="text-sm keep1"><strong>2</strong></span>
                                    <Field type="radio" name="keep" checked={values.keep === '2'} value="2" className="mr-2 leading-tight keep4" />
                                
                                </label>

                                <label id='positive' className="text-gray-500 font-bold mr-2 rounded-pill keep3" >
                                    <span className="text-sm keep1"><strong>3</strong></span>
                                    <Field type="radio" name="keep" checked={values.keep === '3'} value="3" className="mr-2 leading-tight keep4"/>
                                    
                                </label> 

                                
                            </div>

                            
                            <div>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography component={'div'}>INFORMATIONS PERSONNELLES</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>
                                        <div className="form-group col-12 m-2 d-flex flex-row" >

                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Nom<span className='requiredField'>*</span></label>
                                                <Field id="candidateName" type="text" name="candidateName"  className={'form-control' + (errors.candidateName && touched.candidateName ? ' is-invalid' : '')}    
                                                    
                                                />
                                                <ErrorMessage name="candidateName" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Adresse</label>
                                                <Field id="adress" type="text" name="adress"  className={'form-control' + (errors.adress && touched.adress ? ' is-invalid' : '')} 
                                                    
                                                />
                                                <ErrorMessage name="adress" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Prénom <span className='requiredField'>*</span></label>
                                                <Field id="firstName" name="firstName" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} 
                                                />
                                                <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group col-2 m-2 mr-0 ml-5">
                                                <label>Code postal</label>
                                                <Field id="postalCode" name="postalCode"  className={'form-control' + (errors.postalCode && touched.postalCode ? ' is-invalid' : '')} 
                                                />
                                                <ErrorMessage name="postalCode" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group col-3 m-2 ml-0 mr-0">
                                                <label>Ville</label>
                                                <Field id="city" name="city"  className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')} 
                                                />
                                                <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                            </div>

                                            
                                        </div>
                                        
                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Civilité <span className='requiredField'>*</span></label>
                                                <Field name="civility" as="select" className={'form-control' + (errors.civility && touched.civility ? ' is-invalid' : '')}>
                                                <option value=""></option>
                                                <option value="M">Monsieur</option>
                                                <option value="F">Madame</option>
                                                
                                                </Field>
                                                <ErrorMessage name="civility" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Email <span className='requiredField'>*</span></label>
                                                <Field id="email" name="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                            
                                                />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Permis de conduire</label>
                                                {/* <Field id="driverLicence" type="text" name="driverLicence" placeholder="Permis B" className={'form-control' + (errors.driverLicence && touched.driverLicence ? ' is-invalid' : '')}
                                                /> */}

                                                <Field component="div" name="driverLicence">
                                                    <input
                                                        type="radio"
                                                        id="driverLicenceYes"
                                                        defaultChecked={values.driverLicence === "Permis B"}
                                                        name="driverLicence"
                                                        value="Permis B"
                                                        className='mr-2'
                                                    />
                                                    <label htmlFor="driverLicenceYes">Oui</label>

                                                    <input
                                                        type="radio"
                                                        id="driverLicenceNo"
                                                        defaultChecked={values.driverLicence === "Non"}
                                                        name="driverLicence"
                                                        value="Non"
                                                        className='mr-5 mr-2'
                                                    />
                                                    <label htmlFor="driverLicenceNo">Non</label>
                                                </Field>

                                                <ErrorMessage name="driverLicence" component="div" className="invalid-feedback" />
                                            </div>

                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Date de Naissance</label>
                                                <Field
                                                    id="dateOfBirthday" type="text" name="dateOfBirthday" placeholder="12/10/2000" className={'form-control' + (errors.dateOfBirthday && touched.dateOfBirthday ? ' is-invalid' : '')}   
                                                />
                                                <ErrorMessage name="dateOfBirthday" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Téléphone <span className='requiredField'>*</span></label>
                                                <Field id="phone" type="text" name="phone"  className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Nationalité</label>
                                                {/* <Field id="nationality" type="text" name="nationality"  className={'form-control' + (errors.nationality && touched.nationality ? ' is-invalid' : '')}
                                                    
                                                /> */}

                                                <Field
                                                    id="nationality"
                                                    className="custom-select"
                                                    name="nationality"
                                                    options={nationalities}
                                                    component={CustomSelect}
                                                    placeholder="Selectionnez ici"
                                                    isMulti={false}
                                                    
                                                />
                                                <ErrorMessage name="nationality" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Profil</label>
                                                <Field id="profilName" type="text" name="profilName"  className={'form-control' + (errors.profilName && touched.profilName ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="profilName" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Localisation</label>
                                                <Field id="location" type="text" name="location"  className={'form-control' + (errors.location && touched.location ? ' is-invalid' : '')} 
                                                    
                                                />
                                                <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                        </div>

                                        
                                        
                                        
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography component={'div'}>PROJECTION ET MOBILITÉ</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>
                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Type de contrat <span className='requiredField'>*</span></label>
                                                <Field name="contractCandidate" as="select"  className={'form-control' + (errors.contractCandidate && touched.contractCandidate ? ' is-invalid' : '')}>
                                                <option value=""></option>
                                                <option value="0">CDI</option>
                                                <option value="1">CDD</option>
                                                <option value="2">Freelance</option>
                                                <option value="3">Alternance</option>
                                                <option value="4">Stage</option>
                                                <option value="5">Autre</option>
                                                </Field>
                                                <ErrorMessage name="contractCandidate" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Expérience</label>
                                                <Field id="experienceLevel" type="text" name="experienceLevel" placeholder="3 ans" className={'form-control' + (errors.experienceLevel && touched.experienceLevel ? ' is-invalid' : '')}
                                                    
                                                />
                                                <ErrorMessage name="experienceLevel" component="div" className="invalid-feedback" />
                                            </div>
                                                
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Rôle souhaité</label>
                                                <Field id="roleWish" type="text" name="roleWish" className={'form-control' + (errors.roleWish && touched.roleWish ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="roleWish" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Rémunération</label>
                                                <Field id="payWish" type="text" name="payWish" placeholder="2000k" className={'form-control' + (errors.payWish && touched.payWish ? ' is-invalid' : '')}
                                                    
                                                />
                                                <ErrorMessage name="payWish" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Disponibilité</label>
                                                <Field id="disponibility" type="text" name="disponibility" placeholder="12/04/2022" className={'form-control' + (errors.disponibility && touched.disponibility ? ' is-invalid' : '')}
                                                />
                                                <ErrorMessage name="disponibility" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Mobilité</label>
                                                {/* <Field name="mobilityCountry" as="select" className={'form-control' + (errors.mobilityCountry && touched.mobilityCountry ? ' is-invalid' : '')}>
                                                    <option value=""></option>
                                                    <option value="France">France</option>
                                                    <option value="Maroc">Maroc</option>
                                                
                                                </Field> */}
                                                <Field
                                                    id="mobilityCountry"
                                                    className="custom-select"
                                                    name="mobilityCountry"
                                                    options={countries}
                                                    component={CustomSelect}
                                                    placeholder="Selectionnez ici"
                                                    isMulti={false}
                                                    onChange={loadingSpecificRegion(values.mobilityCountry)}
                                                />
                                                
                                                
                                                <ErrorMessage name="mobilityCountry" component="div" className="invalid-feedback" />
                                            </div>

                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Région</label>
                                                {/* <Field id="mobilityRegion" type="text" name="mobilityRegion" className={'form-control' + (errors.mobilityRegion && touched.mobilityRegion ? ' is-invalid' : '')}
                                                /> */}
                                                <Field
                                                    id="mobilityRegion"
                                                    className="custom-select"
                                                    name="mobilityRegion"
                                                    options={regionsData}
                                                    component={CustomSelect}
                                                    placeholder="Selectionnez ici"
                                                    isMulti={false}
                                                />
                                                <ErrorMessage name="mobilityRegion" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Précisions mobilité</label>
                                                <Field id="mobilityDetails" type="text" name="mobilityDetails" className={'form-control' + (errors.mobilityDetails && touched.mobilityDetails ? ' is-invalid' : '')}
                                                    
                                                />
                                                <ErrorMessage name="mobilityDetails" component="div" className="invalid-feedback" />
                                            </div>
                                            
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Client ciblé</label>
                                                <Field id="clientFocused" type="text" name="clientFocused" className={'form-control' + (errors.clientFocused && touched.clientFocused ? ' is-invalid' : '')}
                                                />
                                                
                                                <ErrorMessage name="clientFocused" component="div" className="invalid-feedback" />
                                            </div>
                            
                                        </div>



                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <Typography component={'div'}>COMPÉTENCES, LANGUES</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>
                                        
                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Techniques / Outils</label>
                                                <Field
                                                    className="custom-select"
                                                    name="multiTechnics"
                                                    options={outilsOptions}
                                                    component={CustomSelect}
                                                    placeholder="Selectionnez ici"
                                                    isMulti={true}
                                                />
                                                
                                            </div>

                                            <div className="form-group col-5 m-2 ml-5">
                                                <label>Fonctionnelles / Methodologiques</label>
                                                <Field
                                                    className="custom-select"
                                                    name="multiFonctionnels"
                                                    options={methodologiquesOptions}
                                                    component={CustomSelect}
                                                    placeholder="Selectionnez ici"
                                                    isMulti={true}
                                                />
                                                
                                            </div>
                                            
                                        </div>

                                        <div className="form-group col-12 m-2 d-flex flex-row">
                                            <div className="form-group col-5 m-2 mr-5 ml-4">
                                                <label>Compétences linguistiques</label>
                                                
                                                <Field
                                                    className="custom-select"
                                                    name="multiLanguages"
                                                    options={languageOptions}
                                                    component={CustomSelect}
                                                    placeholder="Selectionnez ici"
                                                    isMulti={true}
                                                />
                                                
                                            </div>
                                            
                                        </div>
                                        <hr />
                                        
                                        <div>
                                            <FieldArray name="languages">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.languages.length > 0 &&
                                                    values.languages.map((language, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite'>Langue {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`languages.${index}.languageName`}>Langue</label> <br />
                                                                    <Field
                                                                    name={`languages.${index}.languageName`}
                                                                    //placeholder="Azure devOps"
                                                                    type="text"
                                                                    className={'form-control' + (errors.languageName && touched.languageName ? ' is-invalid' : '')}
                                                                    />

                                                                    {/* <Field
                                                                        id="languageName"
                                                                        className="custom-select"
                                                                        name={`languages.${index}.languageName`}
                                                                        options={languagesCountry}
                                                                        component={CustomSelect}
                                                                        placeholder="Selectionnez ici"
                                                                        isMulti={false}
                                                                        
                                                                    /> */}

                                                                    <ErrorMessage
                                                                    name={`languages.${index}.languageName`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`languages.${index}.level`}>Niveau</label> <br />
                                                                    <Field
                                                                    name={`languages.${index}.level`}
                                                                    
                                                                    component="select"
                                                                    className={'form-control' + (errors.level && touched.level ? ' is-invalid' : '')}
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="0">A1</option>
                                                                        <option value="1">A2</option>
                                                                        <option value="2">B1</option>
                                                                        <option value="3">B2</option>
                                                                        <option value="4">C1</option>
                                                                        <option value="5">C2</option>
                                                                    
                                                                    </Field>
                                                                    <ErrorMessage
                                                                    name={`languages.${index}.level`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="fontRemoveButton2"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButton' />
                                                                    </span>
                                                                </div>

                                                                
                                                            </div>

                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({ languageName:'',
                                                        level:''})}
                                                        >
                                                        Ajouter une autre langue
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>

                                        <hr />

                                        <div>
                                            <FieldArray name="competences">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.competences.length > 0 &&
                                                    values.competences.map((competence, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite'>Compétence {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`competences.${index}.competenceName`}>Compétence</label> <br />
                                                                    <Field
                                                                    name={`competences.${index}.competenceName`}
                                                                    //placeholder="Azure devOps"
                                                                    type="text"
                                                                    className={'form-control' + (errors.competenceName && touched.competenceName ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`competences.${index}.competenceName`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`competences.${index}.competenceType`}>Type de compétence</label> <br />
                                                                    <Field
                                                                    name={`competences.${index}.competenceType`}
                                                                    
                                                                    component="select"
                                                                    className={'form-control' + (errors.competenceType && touched.competenceType ? ' is-invalid' : '')}
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="0">Technique/Outil</option>
                                                                        <option value="1">Fonctionnelle/Methodologique</option>
                                                                        <option value="2">Général</option>
                                                                    
                                                                    </Field>
                                                                    <ErrorMessage
                                                                    name={`competences.${index}.competenceType`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="secondary"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButton' />
                                                                    </span>
                                                                </div>

                                                                
                                                            </div>

                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({ competenceName:'',
                                                        competenceType:''})}
                                                        >
                                                        Ajouter une autre compétence
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                        
                                        
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                    <Typography component={'div'}>FORMATION</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>

                                    <div>
                                            <FieldArray name="formations">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.formations.length > 0 &&
                                                    values.formations.map((formation, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite'>Formation {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`formations.${index}.diploma`}>Diplôme obtenu</label> <br />
                                                                    <Field
                                                                    name={`formations.${index}.diploma`}
                                                                    //placeholder="Azure devOps"
                                                                    type="text"
                                                                    className={'form-control' + (errors.diploma && touched.diploma ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`formations.${index}.diploma`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`formations.${index}.grade`}>Niveau de formation</label> <br />
                                                                    <Field
                                                                    name={`formations.${index}.grade`}
                                                                    placeholder="M2"
                                                                    type="text"
                                                                    className={'form-control' + (errors.grade && touched.grade ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`formations.${index}.grade`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`formations.${index}.yearOfObtain`}>Année d'obtention</label> <br />
                                                                    <Field
                                                                    name={`formations.${index}.yearOfObtain`}
                                                                    //placeholder="Microsoft"
                                                                    type="text"
                                                                    className={'form-control' + (errors.yearOfObtain && touched.yearOfObtain ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`formations.${index}.yearOfObtain`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>
                                                                <div className="form-group col-5 m-2 ml-5"> 
                                                                    <label htmlFor={`formations.${index}.establishment`}>Établissement</label> <br />
                                                                    <Field
                                                                    name={`formations.${index}.establishment`}
                                                                    //placeholder="Informatique"
                                                                    type="text"
                                                                    className={'form-control' + (errors.establishment && touched.establishment ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`formations.${index}.establishment`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`formations.${index}.mention`}>Mention</label> <br />
                                                                    <Field
                                                                    name={`formations.${index}.mention`}
                                                                    //placeholder="commentaire"
                                                                    type="text"
                                                                    className={'form-control' + (errors.mention && touched.mention ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`formations.${index}.mention`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`formations.${index}.speciality`}>Spécialité / Domaine d'activité</label> <br />
                                                                    <Field
                                                                    name={`formations.${index}.speciality`}
                                                                    //placeholder="commentaire"
                                                                    type="text"
                                                                    className={'form-control' + (errors.speciality && touched.speciality ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`formations.${index}.speciality`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="secondary"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButtonFormation' />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({ diploma:'',
                                                        grade:'',
                                                        yearOfObtain:'',
                                                        establishment:'',
                                                        mention:'',
                                                        speciality:''})}
                                                        >
                                                        Ajouter une autre formation
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>

                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                    <Typography component={'div'}>EXPÉRIENCES</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>

                                        <div>
                                            <FieldArray name="experiences">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.experiences.length > 0 &&
                                                    values.experiences.map((experience, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite'>Expérience {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`experiences.${index}.entreprise`}>Entreprise</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.entreprise`}
                                                                    
                                                                    type="text"
                                                                    className={'form-control' + (errors.entreprise && touched.entreprise ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.entreprise`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`experiences.${index}.fonction`}>Fonction</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.fonction`}
                                                                    placeholder="Chef d'equipe"
                                                                    type="text"
                                                                    className={'form-control' + (errors.fonction && touched.fonction ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.fonction`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`experiences.${index}.mission`}>Mission</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.mission`}
                                                                    placeholder="Developpement Application Bancaire"
                                                                    type="text"
                                                                    className={'form-control' + (errors.mission && touched.mission ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.mission`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>
                                                                <div className="form-group col-5 m-2 ml-5"> 
                                                                    <label htmlFor={`experiences.${index}.dateBegin`}>Date de debut</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.dateBegin`}
                                                                    placeholder="25/02/2020"
                                                                    type="text"
                                                                    className={'form-control' + (errors.dateBegin && touched.dateBegin ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.dateBegin`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`experiences.${index}.dateEnd`}>Date de fin</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.dateEnd`}
                                                                    placeholder="25/02/2022"
                                                                    type="text"
                                                                    className={'form-control' + (errors.dateEnd && touched.dateEnd ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.dateEnd`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`experiences.${index}.reference`}>Référence</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.reference`}
                                                                    placeholder="Mr Dupont"
                                                                    type="text"
                                                                    className={'form-control' + (errors.reference && touched.reference ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.reference`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                
                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`experiences.${index}.experienceComment`}>Complément d'information</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.experienceComment`}
                                                                    
                                                                    type="text"
                                                                    className={'form-control' + (errors.experienceComment && touched.experienceComment ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.experienceComment`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`experiences.${index}.experienceType`}>Type d'expérience</label> <br />
                                                                    <Field
                                                                    name={`experiences.${index}.experienceType`}
                                                                    
                                                                    component="select"
                                                                    className={'form-control' + (errors.experienceType && touched.experienceType ? ' is-invalid' : '')}
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="0">Professionnelle</option>
                                                                        <option value="1">Étude</option>
                                                                        <option value="2">Autre</option>
                                                                    </Field>
                                                                    <ErrorMessage
                                                                    name={`experiences.${index}.experienceType`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="secondary"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButtonExperience' />
                                                                    </span>
                                                                </div>
                                                                
                                                            </div>

                                                            
                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({ entreprise:'',
                                                        fonction:'',
                                                        mission:'',
                                                        dateBegin:'',
                                                        dateEnd:'',
                                                        reference:'',
                                                        experienceComment:'',
                                                        experienceType:'' })}
                                                        >
                                                        Ajouter une autre expérience
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>

                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>


                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                    <Typography component={'div'}>CERTIFICATIONS / FORMATIONS PROFESSIONNELLES</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>
                                        
                                        <div>
                                            <FieldArray name="certifications">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.certifications.length > 0 &&
                                                    values.certifications.map((certification, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite' >Certification {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`certifications.${index}.certificationName`}>Nom</label> <br />
                                                                    <Field
                                                                    name={`certifications.${index}.certificationName`}
                                                                    
                                                                    type="text"
                                                                    className={'form-control' + (errors.certificationName && touched.certificationName ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`certifications.${index}.certificationName`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`certifications.${index}.certificationYear`}>Année d'obtention</label> <br />
                                                                    <Field
                                                                    name={`certifications.${index}.certificationYear`}
                                                                    placeholder="2022"
                                                                    type="text"
                                                                    className={'form-control' + (errors.certificationYear && touched.certificationYear ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`certifications.${index}.certificationYear`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`certifications.${index}.organization`}>Organisme</label> <br />
                                                                    <Field
                                                                    name={`certifications.${index}.organization`}
                                                                    
                                                                    type="text"
                                                                    className={'form-control' + (errors.organization && touched.organization ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`certifications.${index}.organization`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>
                                                                <div className="form-group col-5 m-2 ml-5"> 
                                                                    <label htmlFor={`certifications.${index}.certificationDomain`}>Spécialité / Domaine d'étude</label> <br />
                                                                    <Field
                                                                    name={`certifications.${index}.certificationDomain`}
                                                                    
                                                                    type="text"
                                                                    className={'form-control' + (errors.certificationDomain && touched.certificationDomain ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`certifications.${index}.certificationDomain`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-10 m-2 ml-5">
                                                                    <label htmlFor={`certifications.${index}.certificationComment`}>Précisions</label> <br />
                                                                    <Field
                                                                    name={`certifications.${index}.certificationComment`}
                                                                    placeholder="Payé par son entreprise"
                                                                    type="text"
                                                                    className={'form-control' + (errors.certificationComment && touched.certificationComment ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`certifications.${index}.certificationComment`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="secondary"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButtonCertification' />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({ certificationName:'',
                                                        certificationYear:'',
                                                        organization:'',
                                                        certificationDomain:'',
                                                        certificationComment:'' })}
                                                        >
                                                        Ajouter une autre certification
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>



                                        
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <h4 className='mt-4'>ENTRETIEN</h4>

                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                    <Typography component={'div'}>DATES ENTRETIENS</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>
                                        
                                        <div>
                                            <FieldArray name="interviews">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.interviews.length > 0 &&
                                                    values.interviews.map((interview, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite' >Entretien {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`interviews.${index}.interviewer`}>Entretien</label> <br />
                                                                    <Field
                                                                    name={`interviews.${index}.interviewer`}
                                                                    //placeholder="Azure devOps"
                                                                    component="select"
                                                                    className={'form-control' + (errors.interviewer && touched.interviewer ? ' is-invalid' : '')}
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="0">RH</option>
                                                                        <option value="1">Manager</option>
                                                                        <option value="2">Chef d'équipe</option>
                                                                        <option value="3">Autre</option>
                                                                    </Field>
                                                                    <ErrorMessage
                                                                    name={`interviews.${index}.interviewer`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`interviews.${index}.interviewType`}>Type d'entretien</label> <br />
                                                                    <Field
                                                                    name={`interviews.${index}.interviewType`}
                                                                    
                                                                    component="select"
                                                                    className={'form-control' + (errors.interviewType && touched.interviewType ? ' is-invalid' : '')}
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="0">Phone</option>
                                                                        <option value="1">Visio</option>
                                                                        <option value="2">Local</option>
                                                                        <option value="3">Autre</option>
                                                                    
                                                                    </Field>
                                                                    <ErrorMessage
                                                                    name={`interviews.${index}.interviewType`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                

                                                                
                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`interviews.${index}.interviewerName`}>Nom de la personne qui fait passer l'entretien</label> <br />
                                                                    <Field
                                                                    name={`interviews.${index}.interviewerName`}
                                                                    
                                                                    type="text"
                                                                    className={'form-control' + (errors.interviewerName && touched.interviewerName ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`interviews.${index}.interviewerName`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`interviews.${index}.interviewDate`}>Date de l'entretien</label> <br />
                                                                    <Field
                                                                    name={`interviews.${index}.interviewDate`}
                                                                    placeholder="12/03/2022"
                                                                    type="text"
                                                                    className={'form-control' + (errors.interviewDate && touched.interviewDate ? ' is-invalid' : '')}
                                                                    />
                                                                    <ErrorMessage
                                                                    name={`interviews.${index}.interviewDate`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>


                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="secondary"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButtonEntretien' />
                                                                    </span>
                                                                </div>

                                                            </div>

                                                                

                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({ interviewer:'',
                                                        interviewType:'',
                                                        interviewerName:'',
                                                        interviewDate:''})}
                                                        >
                                                        Ajouter un autre entretien
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>


                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                    <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                                    <Typography component={'div'}>RETOURS</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography component={'div'}>

                                        <div>
                                            <FieldArray name="reports">
                                                {({ insert, remove, push }) => (
                                                <div>
                                                    {values.reports.length > 0 &&
                                                    values.reports.map((report, index) => (
                                                        <div key={index} className='fontBox'>
                                                            <h5 className='fontColorWhite' >Retour {index+1}</h5>
                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                
                                                                <div className="form-group col-5 m-2 ml-5">
                                                                    <label htmlFor={`reports.${index}.reportTitle`}>Titre</label> <br />
                                                                    <Field
                                                                    name={`reports.${index}.reportTitle`}
                                                                    placeholder="Test technique"
                                                                    type="text"
                                                                    className={'form-control' + (errors.reportTitle && touched.reportTitle ? ' is-invalid' : '')}
                                                                    />
                                                                        
                                                                    <ErrorMessage
                                                                    name={`reports.${index}.reportTitle`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-5 m-2 mr-5 ml-4">
                                                                    <label htmlFor={`reports.${index}.reportType`}>Type de retour</label> <br />
                                                                    <Field
                                                                    name={`reports.${index}.reportType`}
                                                                    //placeholder="Azure devOps"
                                                                    component="select"
                                                                    className={'form-control' + (errors.reportType && touched.reportType ? ' is-invalid' : '')}
                                                                    >
                                                                        <option value=""></option>
                                                                        <option value="0">RH</option>
                                                                        <option value="1">Manager</option>
                                                                        <option value="2">Chef d'equipe</option>
                                                                        <option value="3">Autre</option>
                                                                    </Field>
                                                                    <ErrorMessage
                                                                    name={`reports.${index}.reportType`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                

                                                                
                                                            </div>

                                                            <div className="form-group col-12 m-2 d-flex flex-row">
                                                                
                                                                <div className="form-group col-10 m-2 ml-5">
                                                                    <label htmlFor={`reports.${index}.reportComment`}>Compte rendu</label> <br />
                                                                    <Field
                                                                    name={`reports.${index}.reportComment`}
                                                                    as="textarea"
                                                                    className={'form-control p-3' + (errors.reportComment && touched.reportComment ? ' is-invalid' : '')}
                                                                    />
                                                                        
                                                                    <ErrorMessage
                                                                    name={`reports.${index}.reportComment`}
                                                                    component="div"
                                                                    className="field-error"
                                                                    />
                                                                </div>

                                                                <div className="form-group col-2 m-2 ml-5 deleteCertification">
                                                                    <span
                                                                    type="button"
                                                                    //className="secondary"
                                                                    onClick={() => remove(index)}
                                                                    >
                                                                    <DisabledByDefaultRoundedIcon fontSize="large" style={{ backgroundColor: 'white', color: 'red' }} className='fontRemoveButtonRetour' />
                                                                    </span>
                                                                </div>

                                                            </div>

                                                                

                                                            
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <button
                                                        type="button"
                                                        className="btn btn-primary m"
                                                        onClick={() => push({reportTitle:'',
                                                        ReportComment:'',
                                                        reportType:''})}
                                                        >
                                                        Ajouter un autre retour
                                                        </button>
                                                    </div>
                                                </div>
                                                )}
                                            </FieldArray>
                                        </div>


                                        

                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            
                            <button className="btn btn-primary mt-3" type="submit" disabled={isSubmitting} >
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Enregistrer
                            </button>
                            <Link to={isAddMode ? '.' : '..'} className="btn btn-link mt-3">Annuler</Link>
                        </Form>
                    </div>
                );

                    
            }}
        </Formik>
    );
}

export { AddEditCandidate };