import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { fetchWrapper, history } from '../_helpers';

const languageSubject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_APIURL}/api/Language`;

export const languageService = {
    getAllLanguage,
    getLanguageById,
    createLanguage,
    updateLanguage,
    deleteLanguage: _delete,
    language: languageSubject.asObservable(),
    get languageValue () { return languageSubject.value }
};

function getAllLanguage() {
    return fetchWrapper.get(`${baseUrl}/getAll`);
}

function getLanguageById(id) {
    return fetchWrapper.get(`${baseUrl}/getById/${id}`);
}

function createLanguage(params) {
    
    return fetchWrapper.post(`${baseUrl}/add`, params);
}

function updateLanguage(id, params) {
    
    return fetchWrapper.put(`${baseUrl}/update/${id}`, params)
        .then(language => {
            return language;
        });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/delete/${id}`)
        .then(x => {
            return x;
        });
}