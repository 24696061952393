import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { profilService } from '../../_services';
import * as FaIcons from 'react-icons/fa';


function Profils({ match }) {
    const { path } = match;
    const [profils, setProfils] = useState(null);

    useEffect(() => {
        profilService.getAllProfil().then(x => setProfils(x));
    }, []);

    function deleteProfil(id) {
        setProfils(profils.map(x => {
            if (x.profilId === id) { x.isDeleting = true; }
            
            return x;
        }));
        // style={{ fill: 'black' }}
        profilService.deleteProfil(id).then(() => {
            setProfils(profils => profils.filter(x => x.profilId !== id));
        });

    }

    return (
        <div>
            <h2 className="m-3">Liste des profils</h2>
            <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2 rounded-pill addBtn"> <i> <FaIcons.FaPlus /> </i> Ajouter un profil</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Id</th>
                        <th style={{ width: '80%' }}>Profil</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {profils && profils.filter(x => x.name )
                    .map(profil =>
                        <tr key={profil.profilId}>
                            <td> {profil.profilId}</td>
                            <td>{profil.name}</td>
                            
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`${path}/update/${profil.profilId}`} className=" mr-1 editBtn"> <i> <FaIcons.FaEdit  size={27} /> </i> </Link>
                                <Link to="/profil" onClick={() => deleteProfil(profil.profilId)} className="ml-1 deleteBtn"  disabled={profil.isDeleting}>
                                    {profil.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span><FaIcons.FaTrash size={25} /></span>
                                    }
                                </Link>
                            </td>
                        </tr>
                    )}
                    {!profils &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

export { Profils };