import React from 'react'
import { Navbar } from '../../_components/Navbar'

function Interview() {
    return (
        <>
            <Navbar />
            <div className="navAlign">
                
                Interview
            </div>
        </>
    )
}

export  {Interview};
