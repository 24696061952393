import React from 'react';
import { Navbar } from '../../_components/Navbar';
import { Route, Switch } from 'react-router-dom';
import { AddEditLanguage } from './AddEditLanguage';
import { Languages } from './Languages';

function Language({ match }) {
    const { path } = match;
    return (
        <>
            <Navbar />
            <div className="container">
                
                <Switch>
                    <Route exact path={path} component={Languages} />
                    <Route path={`${path}/add`} component={AddEditLanguage} />
                    <Route path={`${path}/update/:id`} component={AddEditLanguage} />
                </Switch>
                {/* <Languages match={path} /> */}
            </div>
        </>
    )
}

export  {Language} ;