import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


import { accountService, alertService } from '../_services';

function Update({ history }) {
    const user = accountService.userValue;
    const initialValues = {
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: '',
        confirmPassword: ''
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required'),
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .when('password', (password, schema) => {
                if (password) return schema.required('Confirm Password is required');
            })
            .oneOf([Yup.ref('password')], 'Passwords must match')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        accountService.update(user.id, fields)
            .then(() => {
                alertService.success('Update successful', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const [isDeleting, setIsDeleting] = useState(false);
    function onDelete() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Etes vous sûr de supprimer ce compte')) {
            setIsDeleting(true);
            accountService.delete(user.id)
                .then(() => alertService.success('Compte supprimé avec succès'));
        }
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <h3>Modifier mon Profil</h3>
                    <div className="form-group ">
                        <div className="form-group col-7 mt-3">
                            <label>Fonction</label>
                            <Field name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')}>

                            </Field>
                            <ErrorMessage name="title" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group col-7 mt-3">
                            <label>Prenom</label>
                            <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-7 mt-3">
                            <label>Nom</label>
                            <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                            <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-7 mt-3">
                            <label>Email</label>
                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    
                        </div>
                    </div>
                    
                    <h4 className="pt-3">Modifier le mot passe</h4>
                    <p>Si vous ne voulez pas modifier le mot de passe n'ecrivez rien</p>
                    <div className="form-group">
                        <div className="form-group col-7 mt-3">
                            <label>Mot de passe</label>
                            <Field name="password" type="text" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-7 mt-3">
                            <label>Confirmation du Mot de Passe</label>
                            <Field name="confirmPassword" type="text" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                        </div>
                    </div>
                    <div className="form-group col-7 mt-5">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary styleButtonUser">
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Modifier
                    
                        </button>
                        <button type="button" onClick={() => onDelete()} className="btn btn-danger styleButtonUser" style={{ width: '130px' }} disabled={isDeleting}>
                            {isDeleting
                                ? <span className="spinner-border spinner-border-sm"></span>
                                : <span> Supprimer </span>
                            }
                        </button>
                        <Link to="." className="btn btn-link styleButtonUser">Annuler</Link>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { Update };