import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { competenceService, alertService } from '../../_services';

function AddEditCompetence({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    const [dataLoading, setDataLoading] = useState(false);
    
    const initialValues = {
        competenceName: '',
        competenceType:''
    };

    const validationSchema = Yup.object().shape({
        competenceName: Yup.string()
            .required('Le nom de la competence est obligatoire'),
        competenceType: Yup.string()
            .required('Le type de la competence est obligatoire')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        if (isAddMode) {
            createCompetence(fields, setSubmitting);
        } else {
            updateCompetence(id, fields, setSubmitting);
        }
    }

    function createCompetence(fields, setSubmitting) {
        fields.competenceType = parseInt(fields.competenceType);
        competenceService.createCompetence(fields)
            .then(() => {
                alertService.success('Competence ajoutée avec succès', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    function updateCompetence(id, fields, setSubmitting) {
        fields.CompetenceId = parseInt(id);
        fields.competenceType = parseInt(fields.competenceType);
        
        competenceService.updateCompetence(id, fields)
            .then(() => {
                alertService.success('Competence modifiée', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const loadingCompetenceUpdate = (setFieldValue) => {
        if (!isAddMode) {
            // get Competence and set form fields
            competenceService.getCompetenceById(id).then(competence => {
                const fields = ['competenceName','competenceType'];
                fields.forEach(field => setFieldValue(field, competence[field], false));
            });
        }
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => {
                
                if(!isAddMode && !dataLoading){
                    loadingCompetenceUpdate(setFieldValue);
                    setDataLoading(true);
                }
                
                return (
                    <Form>
                        <h1>{isAddMode ? "Ajout d'une Competence" : "Modification d'une Competence"}</h1>
                        
                        <div className="form-group col-5">
                            <label>Nom</label>
                            <Field name="competenceName" type="text" className={'form-control' + (errors.competenceName && touched.competenceName ? ' is-invalid' : '')} />
                            <ErrorMessage name="competenceName" component="div" className="invalid-feedback" />
                        </div>
                    
                        <div className="form-group col-3">
                            <label>Type</label>
                            <Field name="competenceType" as="select" className={'form-control' + (errors.competenceType && touched.competenceType ? ' is-invalid' : '')}>
                                <option value=""></option>
                                <option value="0">Technique/Outil</option>
                                <option value="1">Fonctionnelle/Methodologique</option>
                            </Field>
                            <ErrorMessage name="competenceType" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Enregistrer
                            </button>
                            <Link to={isAddMode ? '.' : '..'} className="btn btn-warning ml-3">Annuler</Link>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export { AddEditCompetence };