import React from 'react';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as CgIcons from 'react-icons/cg';
import * as GrIcons from 'react-icons/gr';
import * as ImIcons from 'react-icons/im';
import * as GiIcons from 'react-icons/gi';

let iconStyles = { color: "black", fontSize: "1.5em" };
// ImProfile , GiSkills

export const SidebarData = [
  {
    title: 'Tableau de Bord',
    path: '/dashboard',
    icon: <AiIcons.AiOutlineDashboard />,
    cName: 'nav-text'
  },
  {
    title: 'Entretiens',
    path: '/interviews',
    icon: <CgIcons.CgNotes />,
    cName: 'nav-text'
  },
  {
    title: 'Candidats',
    path: '/candidates',
    icon: <GrIcons.GrUserManager />,
    cName: 'nav-text'
  },
  {
    title: 'Profils',
    path: '/profil',
    icon: <ImIcons.ImProfile />,
    cName: 'nav-text'
  },
  {
    title: 'Langues',
    path: '/language',
    icon: <GrIcons.GrLanguage />,
    cName: 'nav-text'
  },
  {
    title: 'Competences',
    path: '/competence',
    icon: <GiIcons.GiSkills />,
    cName: 'nav-text'
  },
  {
    title: 'Gestion',
    path: '/admin',
    icon: <BsIcons.BsFillGearFill />,
    cName: 'nav-text'
  }
  
];