import React from 'react';
import { Link } from 'react-router-dom';
import logo from "./../public/icons/user.jpg";

import { accountService } from '../_services';


function Details({ match }) {
    
    const { path } = match;
    const user = accountService.userValue;
    const log = "./.."+logo;
    
    return (
        <div className='container'>
            <h2>Mon Profil</h2>
            

            <div className="card" style={{ width: '300px' }}>
                
                <img className="card-img-top avatarProfil rounded-circle rounded mx-auto d-block mt-3" src={logo}  alt="Card image"  />

                <div className="card-body">
                    <h4 className="card-title justify-content-center">{user.firstName} {user.lastName}</h4>
                    <p className="card-text justify-content-center"> 
                        <strong className='justify-content-center'>Email : </strong> {user.email} <br />
                        <strong className='justify-content-center'>Fonction : </strong> {user.title} <br />
                        <strong className='justify-content-center'>Role: </strong> {user.role}<br /> 
                    </p>
                    <p><Link to={`${path}/update`} className='btn btn-primary mt-3'>Modifier Profil</Link></p>
                </div>
            </div>
        </div>
    );
}

export { Details };