import React from 'react';
import ReactToPrint from 'react-to-print';
import TableComponent from './TableComponent';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
 
class ExportPdfComponent extends React.Component {
     
    render() {
        const {id} = this.props.match.params
        

        const marginTop="10px";
        const marginRight="5px";
        const marginBottom="10px";
        const marginLeft="15px";
        const pageStyle = `
            @page {
              size: 80mm 50mm;
            }

            @media all {
              .pagebreak {
                display: none;
              }
            }

            h4 {
              
                text-decoration: underline;
                color:white;
                background: #25b9cc;
                width:fit-content;
              
            }

            @media print {
              .pagebreak {
                page-break-before: always;
              }
            }
          `;

        const getPageMargins = () => {
            return `

            @page { 
              margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; 
              size: landscape;
              display: block;

            } 
            
            @media all {
              .page-break {
                display: none;
              }
            }
            
            @media print {
              html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
              }
            }
            
            @media print {
              .page-break {
                margin-top: 1rem;
                display: block;
                page-break-before: auto;
              }
            }
            `;
        };
      return (
        <div>
            
            
   
          <TableComponent ref={(response) => (this.componentRef = response)} idCandidate={id}/>
          
          <ReactToPrint
            content={() => this.componentRef}
            pageStyle={getPageMargins()}
            trigger={() => <button className="btn btn-primary"><FileDownloadOutlinedIcon /> PDF</button>}
          />

          <br />
          <br />
          <br />

        </div>
      );
    }
}
 
export default ExportPdfComponent;