import React from 'react';
import { Navbar } from '../../_components/Navbar';
import { Route, Switch } from 'react-router-dom';
import { AddEditCompetence } from './AddEditCompetence';
import { Competences } from './Competences';

function Competence({ match }) {
    const { path } = match;
    return (
        <>
            <Navbar />
            <div className="container">
                {/* <Route exact path={path} component={Competences} /> */}
                <Switch>
                    <Route exact path={path} component={Competences} />
                    <Route path={`${path}/add`} component={AddEditCompetence} />
                    <Route path={`${path}/update/:id`} component={AddEditCompetence} />
                </Switch>
                
            </div>
        </>
    )
}

export  {Competence} ;