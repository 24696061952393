const languageOptions = [
    {
        label: "Français Bilingue",
        value: "Français-Bilingue"
    },
    {
        label: "Français Courant",
        value: "Français-Courant"
    },
    {
        label: "Français Professionnel",
        value: "Français-Professionnel"
    },
    {
        label: "Français Scolaire",
        value: "Français-Scolaire"
    },
    {
        label: "Anglais Bilingue",
        value: "Anglais-Bilingue"
    },
    {
        label: "Anglais Courant",
        value: "Anglais-Courant"
    },
    {
        label: "Anglais Professionnel",
        value: "Anglais-Professionnel"
    },
    {
        label: "Anglais Scolaire",
        value: "Anglais-Scolaire"
    },
    {
        label: "Arabe Bilingue",
        value: "Arabe-Bilingue"
    },
    {
        label: "Arabe Courant",
        value: "Arabe-Courant"
    },
    {
        label: "Arabe Professionnel",
        value: "Arabe-Professionnel"
    },
    {
        label: "Arabe Scolaire",
        value: "Arabe-Scolaire"
    },
    {
        label: "Hindi Bilingue",
        value: "Hindi-Bilingue"
    },
    {
        label: "Hindi Courant",
        value: "Hindi-Courant"
    },
    {
        label: "Hindi Professionnel",
        value: "Hindi-Professionnel"
    },
    {
        label: "Hindi Scolaire",
        value: "Hindi-Scolaire"
    },
    {
        label: "Mandarin Bilingue",
        value: "Mandarin-Bilingue"
    },
    {
        label: "Mandarin Courant",
        value: "Mandarin-Courant"
    },
    {
        label: "Mandarin Professionnel",
        value: "Mandarin-Professionnel"
    },
    {
        label: "Mandarin Scolaire",
        value: "Mandarin-Scolaire"
    },
    {
        label: "Espagnol Bilingue",
        value: "Espagnol-Bilingue"
    },
    {
        label: "Espagnol Courant",
        value: "Espagnol-Courant"
    },
    {
        label: "Espagnol Professionnel",
        value: "Espagnol-Professionnel"
    },
    {
        label: "Espagnol Scolaire",
        value: "Espagnol-Scolaire"
    },
    {
        label: "Portugais Bilingue",
        value: "Portugais-Bilingue"
    },
    {
        label: "Portugais Courant",
        value: "Portugais-Courant"
    },
    {
        label: "Portugais Professionnel",
        value: "Portugais-Professionnel"
    },
    {
        label: "Portugais Scolaire",
        value: "Portugais-Scolaire"
    },
    {
        label: "Bengali Bilingue",
        value: "Bengali-Bilingue"
    },
    {
        label: "Bengali Courant",
        value: "Bengali-Courant"
    },
    {
        label: "Bengali Professionnel",
        value: "Bengali-Professionnel"
    },
    {
        label: "Bengali Scolaire",
        value: "Bengali-Scolaire"
    },
    {
        label: "Ourdou Bilingue",
        value: "Ourdou-Bilingue"
    },
    {
        label: "Ourdou Courant",
        value: "Ourdou-Courant"
    },
    {
        label: "Ourdou Professionnel",
        value: "Ourdou-Professionnel"
    },
    {
        label: "Ourdou Scolaire",
        value: "Ourdou-Scolaire"
    },
    {
        label: "Italien Bilingue",
        value: "Italien-Bilingue"
    },
    {
        label: "Italien Courant",
        value: "Italien-Courant"
    },
    {
        label: "Italien Professionnel",
        value: "Italien-Professionnel"
    },
    {
        label: "Italien Scolaire",
        value: "Italien-Scolaire"
    },
    {
        label: "Russe Bilingue",
        value: "Russe-Bilingue"
    },
    {
        label: "Russe Courant",
        value: "Russe-Courant"
    },
    {
        label: "Russe Professionnel",
        value: "Russe-Professionnel"
    },
    {
        label: "Russe Scolaire",
        value: "Russe-Scolaire"
    },
    {
        label: "Allemand Bilingue",
        value: "Allemand-Bilingue"
    },
    {
        label: "Allemand Courant",
        value: "Allemand-Courant"
    },
    {
        label: "Allemand Professionnel",
        value: "Allemand-Professionnel"
    },
    {
        label: "Allemand Scolaire",
        value: "Allemand-Scolaire"
    }

];

export default languageOptions;