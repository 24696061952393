import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { candidateService, alertService } from '../../_services/index';
import * as FaIcons from 'react-icons/fa';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from "@mui/material/TextField";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// images 
import logo from "./../../public/icons/avatar1.png";
import manLogo from "./../../public/icons/man.png";
import womanLogo from "./../../public/icons/woman.png";
import flagFrance from "./../../public/icons/flag_france.png";
import flagMorroco from "./../../public/icons/flag_morroco.png";
import wordflag from "./../../public/icons/worldwide.png";

// import {
//     CircularProgressbar,
//     CircularProgressbarWithChildren,
//     buildStyles
//   } from "react-circular-progressbar";
//   import "react-circular-progressbar/dist/styles.css";



function Candidates({ match }) {
    const { path } = match;
    const [candidates, setCandidates] = useState(null);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        candidateService.getAllCandidate().then( x => {
            const data = [...x].sort( (a,b) => {
                return a.candidateName > b.candidateName ? 1 : -1
            } );
            setCandidates(data);
        });
        
    }, []);



    function deleteCandidate(id) {
        setCandidates(candidates.map(x => {
            if (x.candidateId === id) { x.isDeleting = true; }
            
            return x;
        }));
        // style={{ fill: 'black' }}
        candidateService.deleteCandidate(id).then(() => {
            setCandidates(candidates => candidates.filter(x => x.candidateId !== id));
        });

    }

   

    const choiceGender = (gender) => {
        if (gender == "M") return manLogo;
        if (gender == "F") return womanLogo;
        return logo;
    }

    const choiceKeep = (keep) => {
        if (keep == -1 ) {
            return <strong className='notify-badge-negative rounded-pill'>{keep}</strong>;
        }
        else{
            return <strong className='notify-badge rounded-pill'>{keep}</strong>;
        }
        
    }

    const choiceFlag = (flag) => {
        if (flag == "France") return flagFrance;
        if (flag == "Maroc") return flagMorroco;
        return wordflag;
    }

    const choiceContractType = (contract) => {
        switch (contract) {
            case 0: return "CDI";
            case 1 : return "CDD";
            case 2 : return "Freelance";
            case 3 : return "Alternance";
            case 4 : return "Stage";
            case 5 : return "Autre Contrat";
            default:
                break;
        }
    }

    const submitDeleteCandidate = (candidate) => {

        confirmAlert({
          title: 'Suppression',
          message: 'Êtes vous sûr de vouloir supprimer ce candidat',
          buttons: [
            {
              label: 'Oui',
              onClick: () => deleteCandidate(candidate)
            },
            {
              label: 'Non',
              onClick: () => alertService.info("Suppression Annulée")
            }
          ]
        });
    }

    

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = candidates.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(candidates)
        }
    }

    

    return (
        <div className="container">
            <h2 className="m-3">Liste des Candidats</h2>
            <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2 rounded-pill addBtn"> <i> <FaIcons.FaPlus /> </i> Ajouter un Candidat</Link>
            <div className="search mt-3">
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    label="Rechercher"
                    placeholder='Chercher un candidat'
                    onChange={(e) => searchItems(e.target.value)}
                />
            </div>
    
            <div className='d-flex flex-row flex-wrap'>

            {searchInput.length >= 1 ? (
                    filteredResults.map((candidate, index) =>

                    <div key={index} className="card col-3 m-3" style={{ width: '285px' }}>
                        <div className='d-flex flex-row'>
                            <div className='headerCard'>
                                <Link to="/candidates" onClick={() => submitDeleteCandidate(candidate.candidateId) } className="ml-3 mr-5 deleteBtn"  disabled={candidate.isDeleting}>
                                        {candidate.isDeleting 
                                            ? <span className="spinner-border spinner-border-sm"></span>
                                            : <span><FaIcons.FaTrash size={25} /> Supprimer</span>
                                        }
                                </Link>
                            </div>
                            <div className='headerCardUpdate'>
                                <Link to={`${path}/update/${candidate.candidateId}`} className=" ml-3 editBtn"> <i> <FaIcons.FaEdit  size={27} /> Modifier </i> </Link>
                            </div>
                        </div>
                        <div className='item'>
                            <span className='rounded-circle'>{choiceKeep(candidate.keepInTouch.keep) }</span>
                            {/* <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                                {percentage => (
                                <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                )}
                            </ChangingProgressProvider> */}
                            <img className="card-img-top avatar rounded-circle rounded mx-auto d-block mt-0" src={choiceGender(candidate.civility)}  alt="Card image"  />

                        </div>
                        
                        <div className="card-body">
                            <h5 className="card-title d-flex justify-content-center">{candidate?.firstName} {candidate?.candidateName.toUpperCase()}</h5>
                            <div className="card-text d-flex justify-content-center"> 
                                <div className='col-4 d-flex justify-content-center flagStyle'><img src={choiceFlag(candidate?.mobility?.mobilityCountry)}  alt="drapeau"  /> </div>
                                <div className='col-8 d-flex flex-column '> 
                                    <div className='fontComponent'>
                                        {candidate?.roleWish} 
                                    </div>
                                    <div className='fontComponent'>
                                        {candidate?.infoCandidate.experienceLevel}
                                    </div>
                                 
                                </div>
                            </div>
                            <div>
                                <span className='fontComponent'> { choiceContractType (candidate?.contractCandidate)} </span> 
                                <span className='fontComponent'> {candidate?.payWish} € </span> 
                                <span className='fontComponent'>Dispo : {candidate.infoCandidate.disponibility} </span>
                            </div>

                            <div>
                                {candidate.competences && candidate?.competences?.map( (competence, index) =>
                                    
                                    index < 5 && competence.competenceName.length > 0 ? <span key={index} className='btn btn-info competenceForm'> {competence.competenceName} </span> : <span key={index} className='competenceForm2'></span>
                                )
                                }
                            </div>
                            <div className='mt-3'>
                                <span>
                                    <Link className='btn btn-primary profilForm' to={`${path}/getById/${candidate.candidateId}`} > <AccountBoxOutlinedIcon /> VOIR LE PROFIL</Link>
                                </span>
                            </div>
                                
                            <div className='dctForm mt-2'>
                                <span > 
                                    <Link className='btn btn-primary dctFormChildren rounded-pill' to={`${path}/getSkillsCandidate/${candidate.candidateId}`} >DCT</Link>
                                </span>

                                <span >
                                    <Link to={`${path}/update/${candidate.candidateId}`} className="btn btn-primary dctFormChildren rounded-pill"> <i> <AddCircleOutlineOutlinedIcon /> CR </i> </Link>
                                </span>
                                
                            </div>
                        </div>
                    </div>

                )) :
            
                
                (candidates && candidates.map((candidate, index) =>

                    <div key={index} className="card col-3 m-3" style={{ width: '285px' }}>
                        <div className='d-flex flex-row'>
                            <div className='headerCard'>
                                <Link to="/candidates" onClick={() => submitDeleteCandidate(candidate.candidateId) } className="ml-3 mr-5 deleteBtn"  disabled={candidate.isDeleting}>
                                        {candidate.isDeleting 
                                            ? <span className="spinner-border spinner-border-sm"></span>
                                            : <span><FaIcons.FaTrash size={25} /> Supprimer</span>
                                        }
                                </Link>
                            </div>
                            <div className='headerCardUpdate'>
                                <Link to={`${path}/update/${candidate.candidateId}`} className=" ml-3 editBtn"> <i> <FaIcons.FaEdit  size={27} /> Modifier </i> </Link>
                            </div>
                        </div>
                        <div className='item'>
                            <span className='rounded-circle'>{choiceKeep(candidate.keepInTouch.keep) }</span>
                            <img className="card-img-top avatar rounded-circle rounded mx-auto d-block mt-0" src={choiceGender(candidate.civility)}  alt="Card image"  />

                        </div>
                        
                        <div className="card-body">
                            <h5 className="card-title d-flex justify-content-center">{candidate?.firstName} {candidate?.candidateName.toUpperCase()}</h5>
                            <div className="card-text d-flex justify-content-center"> 
                                <div className='col-4 d-flex justify-content-center flagStyle'><img src={choiceFlag(candidate?.mobility?.mobilityCountry)}  alt="drapeau"  /> </div>
                                <div className='col-8 d-flex flex-column '> 
                                    <div className='fontComponent'>
                                        {candidate?.roleWish} 
                                    </div>
                                    <div className='fontComponent'>
                                        {candidate?.infoCandidate.experienceLevel}
                                    </div>
                                 
                                </div>
                            </div>
                            <div>
                                <span className='fontComponent'>{ choiceContractType (candidate?.contractCandidate)}</span> 
                                <span className='fontComponent'>{candidate?.payWish} €</span>
                                <span className='fontComponent'>{candidateService.diponibilityDate(candidate.infoCandidate.disponibility)}</span>
                            </div>

                            <div className='fontCompetences'>
                                {candidate.competences && candidate?.competences?.map( (competence, index) =>
                                    
                                    index < 5 && competence.competenceName.length > 0 ? <span key={index} className='btn btn-info competenceForm'> {competence.competenceName} </span> : <span key={index} className='competenceForm2'></span>
                                )
                                }
                            </div>
                            <div className='mt-3'>
                                <span>
                                    <Link className='btn btn-primary profilForm' to={`${path}/getById/${candidate.candidateId}`} > <AccountBoxOutlinedIcon /> VOIR LE PROFIL</Link>
                                </span>
                            </div>
                                
                            <div className='dctForm mt-2'>
                                <span > 
                                    <Link className='btn btn-primary dctFormChildren rounded-pill' to={`${path}/getSkillsCandidate/${candidate.candidateId}`} >DCT</Link>
                                </span>

                                <span >
                                    <Link to={`${path}/update/${candidate.candidateId}`} className="btn btn-primary dctFormChildren rounded-pill"> <i> <AddCircleOutlineOutlinedIcon /> CR </i> </Link>
                                </span>
                                
                            </div>
                        </div>
                    </div>

                ))}
                
                {!candidates &&
                        <div>
                            <span colSpan="4" className="text-center">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </span>
                        </div>
                }
            </div>
            

        </div>
    );
}

export { Candidates };