import React from 'react';
import { Navbar } from '../../_components/Navbar';
import { Route, Switch } from 'react-router-dom';
import { AddEditProfil } from './AddEditProfil';
import { Profils } from './Profils';

function ProfilCandidate({ match }) {
    const { path } = match;
    return (
        <>
            <Navbar />
            <div className="container">
                {/* <Route exact path={path} component={Profils} /> */}
                <Switch>
                    <Route exact path={path} component={Profils} />
                    <Route path={`${path}/add`} component={AddEditProfil} />
                    <Route path={`${path}/update/:id`} component={AddEditProfil} />
                </Switch>
                {/* <Profils match={path} /> */}
            </div>
        </>
    )
}

export  {ProfilCandidate} ;